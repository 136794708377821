import React from 'react';
import { useTranslation } from 'react-i18next';

import digitize from 'assets/img/digitize.jpg';
import digitizeWebP from 'assets/img/digitize.webp';
import digitizeX2 from 'assets/img/digitize-2x.webp';
import procurement from 'assets/img/procurement.jpg';
import procurementWebP from 'assets/img/procurement.webp';
import procurementX2 from 'assets/img/procurement-2x.webp';
import { Footer, Header, ImageWithList, JoinUsBanner, SeoHelmet } from 'components';

import pages from '../../dictionaries/pages.json';
import Icons from './sections/Icons';
import Logos from './sections/Logos';
import Solutions from './sections/Solutions';
import Steps from './sections/Steps';
import StopSection from './sections/StopSection';
import Stories from './sections/Stories';
import TickSection from './sections/TickSection';
import TopSection from './sections/TopSection';
import Video from './sections/Video';

import style from './LandingPage.module.scss';

const data = [
  {
    imageOnLeft: true,
    image: procurement,
    sources: [
      { src: procurementWebP, size: '1x', type: 'image/webp' },
      { src: procurementX2, size: '2x', type: 'image/webp' }
    ],
    imageAlt: 'woman',
    subtitle: 'Buy with avalio',
    title: 'Procurement, redefined',
    button: {
      label: 'Schedule a Demo',
      link: '/contact'
    },
    list: [
      'Manage your spend in one command center',
      'Get actionable analytics',
      'Keep your processes in order',
      'Abandon paper processes, go 100% digital'
    ]
  },
  {
    imageOnLeft: false,
    image: digitize,
    sources: [
      { src: digitizeWebP, size: '1x', type: 'image/webp' },
      { src: digitizeX2, size: '2x', type: 'image/webp' }
    ],
    imageAlt: 'woman',
    subtitle: 'Become a vendor',
    title: 'Digitize your sales',
    button: {
      label: 'Schedule a Demo',
      link: '/contact'
    },
    list: [
      'No more time burned on taking orders manually',
      'Increase your sales by reaching new clients',
      'Reduce cost of sales and forecast client demand better',
      'Focus on product marketing where human touch is needed'
    ]
  }
];
const LandingPage = () => {
  const { t } = useTranslation('page');
  return (
    <>
      <SeoHelmet
        title={t('home.title')}
        description={t('home.description')}
      />
      <div className={style.container}>
        <Header />
        <TopSection />
        <Logos />
        <Video />
        <Icons />
        <StopSection />
        <Steps />
        {/* <Stories /> */}
        {/* <TickSection /> */}
        {/* <Solutions /> */}
        {/* <ImageWithList section={data[0]} /> */}
        {/* <ImageWithList section={data[1]} /> */}
        <JoinUsBanner />
        <Footer />
      </div>
    </>
  );
};

export default LandingPage;
