import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { Footer, Header } from 'components';

import pages from 'src/dictionaries/pages.json';

import SeoHelmet from '../../components/layout/SeoHelmet';

import style from './NotFoundRoute.module.scss';

const NotFoundRoute = () => {
  const { t } = useTranslation('common');
  const location = useLocation();

  return (
    <>
      <SeoHelmet title={pages.notFoundRoute.title} />
      <Header />
      <div className={style.container}>
        <h1 className={style.main}>404</h1>
        <h2 className={style.second}>{(location.state?.message || t('error.message')) + '. '}</h2>
        <h5>
          {t('error.text1')} <Link style={{ fontSize: 'inherit' }} to={'/'}>{t('error.text2')}</Link>
        </h5>
      </div>
      <Footer />
    </>
  );
};

export default NotFoundRoute;
