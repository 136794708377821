import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import join from 'assets/img/join.jpg';
import joinWebP from 'assets/img/join.webp';
import join2x from 'assets/img/join-2x.webp';
import { Footer, Header, ImageWithList, SeoHelmet } from 'components';

import { CONTACT_EMAIL } from '../../constants/misc';
import OurVision from './sections/OurVision';
import TopSection from './sections/TopSection';

import style from './About.module.scss';

const About = () => {
  const { i18n, t } = useTranslation('page');

  const data = useMemo(
    () => ({
      darkBg: true,
      imageOnLeft: true,
      image: join,
      sources: [
        { src: joinWebP, size: '1x', type: 'image/webp' },
        { src: join2x, size: '2x', type: 'image/webp' },
      ],
      imageAlt: 'woman',
      subtitle: t('about.joinOurTeam.subTitle'),
      title: t('about.joinOurTeam.title'),
      button: {
        label: t('about.joinOurTeam.button'),
        link: '/contact'
      },
      content: [
        <>
          {t('about.joinOurTeam.text1')}
        </>,
        <>
          {t('about.joinOurTeam.text2')} <a href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</a>
        </>
      ]
    }),
    [i18n.language]
  );

  return (
    <>
      <SeoHelmet title={t('about.title')} />
      <div className={style.container}>
        <Header />
        <TopSection />
        <OurVision />
        <ImageWithList section={data} />
        <Footer />
      </div>
    </>
  );
};

export default About;
