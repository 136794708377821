import { useTranslation } from 'react-i18next';

import { Footer, Header, SeoHelmet } from 'components';

import Main from './components/Main';

const ContactPage = () => {
  const { t } = useTranslation('page');
  return (
    <>
      <SeoHelmet
        title={t('contact.title')}
        description={t('contact.description')}
      />
      <div style={{ overflow: 'hidden' }}>
        <Header />
        <Main />
        <Footer />
      </div>
    </>
  );
};

export default ContactPage;
