import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'components';

import style from './TopSection.module.scss';

const TopSection = () => {
  const { t } = useTranslation('page');
  return (
    <div className={style.container}>
      <div className={style.content}>
        <h1 className={style.title}>
          {t('benefits.topSection.title')}
        </h1>
        <p className={style.text}>
          {t('benefits.topSection.text')}
        </p>
        <Button
          label={t('common:navigation.scheduleDemo')}
          to={'/contact'}
        />
      </div>
    </div>
  );
};

export default TopSection;
