import React from 'react';
import { useTranslation } from 'react-i18next';

import tablet from 'assets/img/tablet.png';

import style from './StopSection.module.scss';

const StopSection = () => {
    const { t } = useTranslation('page');
    return (
        <div className={style.container}>
            <div className={style.content}>
                <div className={style.columns}>
                    <h1 className={style.title}>
                        {t('home.stopSection.title')}
                    </h1>
                    <div className={style.text}>
                        {t('home.stopSection.description')}
                    </div>
                    <ul className={style.list}>
                        <li className={style.listItem}>{t('home.stopSection.text1')}</li>
                        <li className={style.listItem}>{t('home.stopSection.text2')}</li>
                        <li className={style.listItem}>{t('home.stopSection.text3')}</li>
                    </ul>
                </div>
                <div className={style.imageContainer}>
                    <img src={tablet} alt='tablet' className={style.image} />
                </div>
            </div>
        </div>
    );
};

export default StopSection;