import { Link } from 'react-router-dom';

import { LegalTermWrapper } from 'components';

import style from 'src/components/layout/LegalTermWrapper/LegalTermWrapper.module.scss';

const PrivacyPolicy = () => {
    return (
        <LegalTermWrapper pageTitle={'Privacy policy'}>
            <p className={style.text}>
                This document (hereinafter referred to as the &quot;Policy&quot;) contains information regarding the processing by: CBM 16 Limited Liability Company with its registered office in Jasionka, address: 36-002 Jasionka 954 E, entered in the Register of Entrepreneurs of the National Court Register by the District Court in Rzeszów, XII Economic Department of the National Court Register under KRS number: 0000809744, NIP: 5170402504, REGON: 384670823 with a share capital of PLN 10,000, (hereinafter referred to as the &quot;Administrator&quot;) personal data of persons using the Internet Platform available at www.dentametr.pl (hereinafter referred to as the &quot;Platform&quot;). The Policy is made available in order to provide persons whose personal data are processed by the Administrator with the broadest possible information about the scope of the processed data, the methods and principles of data processing and the rights of such persons.
            </p>
            <p className={style.text}>
                Users&apos; Personal Data is processed in accordance with the Personal Data Protection Act of May 10, 2018, as well as the Regulation of the European Parliament and of the Council (EU) 2016/679 of April 27, 2016 on the protection of natural persons in relation to the processing of personal data and on the free flow of such data and repealing Directive 95/46/EC (hereinafter referred to as &quot;RODO&quot;), the Act on the provision of electronic services of July 18, 2002 (Journal of Laws No. 144, item 1204, as amended) and the Act of July 16, 2004. - Telecommunications Law (Journal of Laws 2004 No. 171 item 1800 as amended).
            </p>
            <p className={style.text}>
                The Administrator shall exercise special care to protect the interests of data subjects, and in particular shall ensure that the data he collects are processed in accordance with the law; collected for specified, legitimate purposes and not subjected to further processing incompatible with those purposes; substantively correct and adequate in relation to the purposes for which they are processed; and stored in a form that allows the identification of data subjects for no longer than necessary to achieve the purpose of processing and in a minimum quantity.
            </p>
            <p className={style.text}>
                All words, phrases and acronyms appearing on this Site and beginning with a capital letter (e.g., User, Supplier&apos;s Commercial Offer, Goods, Services) shall be understood in accordance with their definition contained in the Terms and Conditions of the Platform available at: <Link to='/statute'>avalio.io/statute</Link>
            </p>
            <h2 className={style.header}>I. SCOPE OF APPLICATION</h2>
            <p className={style.text}>
                This Policy applies to the processing of Personal Data in connection with the use of the Platform and the ordering of Goods by Users through the Platform.
            </p>
            <h2 className={style.header}>II. LEGAL BASIS, PURPOSES, PRINCIPLES AND DURATION OF DATA PROCESSING ON THE PLATFORM</h2>
            <ol className={style.list}>
                <li>
                    Users may leave their data on the Platform in the following cases:
                    <ol className={style.second_list}>
                        <li>filling out the Registration Form and registering an Account on the Platform;</li>
                        <li>subscribing to the newsletter;</li>
                        <li>ontacting by phone, email, filling out the Order Form.</li>
                    </ol>
                </li>
                <li>
                    Personal Data is processed by the Administrator in order to:
                    <ol className={style.second_list}>
                        <li>
                            establishing, shaping the content of the legal relationship and changing it, as well as for exercising the rights and obligations arising from the contract concluded between the User and the Administrator/Operator, (Article 6(1)(b) RODO) as well as for providing the User with information and the Goods ordered by the User, which is necessary for the performance of mutual contractual obligations and for the purpose of processing complaints and claims related to the concluded contracts (processing is necessary for the fulfillment of the Administrator&apos;s legal obligation - Article 6(1)(c) RODO) - for the time necessary for the performance of the contract, and after its termination, the data will be stored for the period necessary to demonstrate the correctness of the performance of obligations under the contract, until the expiration of the deadlines indicated in the regulations on archiving and the expiration of the deadlines for the assertion of claims under the contract;
                        </li>
                        <li>
                            to provide the User (in a manner consistent with applicable regulations) with marketing materials and information, instructions and directions necessary for the performance of the contract - data processing then takes place with the User&apos;s consent (Article 6(1)(a) RODO) and/or in the legitimate interest of the Administrator which is the improvement of the services/goods offered and direct marketing (Article 6(1)(f) RODO) for the time necessary for the fulfillment of the Administrator&apos;s legitimate interest until the User raises a legitimate objection, and in the case of direct marketing no longer than until the User raises an objection;
                        </li>
                        <li>
                            to perform other statutory duties of the Administrator, in particular tax and reporting duties (Article 6(1)(c) RODO) - for the time necessary to fulfill the Administrator&apos;s statutory obligations, in particular until the expiration of the statute of limitations for tax liabilities;
                        </li>
                        <li>
                            to improve the functioning of the Platform and the scope of the Supplier&apos;s Commercial Offer, which is the legitimate interest of the Administrator (Article 6(1)(f) RODO); Personal Data is then processed for the time necessary to realize the Administrator&apos;s legitimate interest or until the User makes a legitimate objection;
                        </li>
                        <li>
                            to perform mutual contractual obligations, to draft, exercise or defend contractual claims (Article 6(1)(b) RODO), to fulfill the Administrator&apos;s statutory obligations (Article 6(1)(c) RODO), as well as is carried out in the Administrator&apos;s legitimate interest (to ensure the high quality of the Services provided to the User) (Article 6(1)(f) RODO) - for the time necessary for the performance of the contract, and after its termination, the data will be kept for the period necessary to demonstrate the correctness of the performance of the obligations under the contract until the expiration of the deadlines indicated in the archiving regulations; in the case of the performance of statutory obligations by the Administrator, the processing of Personal Data will be carried out for the time necessary for the performance of the Administrator&apos;s statutory obligations; in the case of ensuring security, for the time necessary for the performance of the Administrator&apos;s legitimate interest or until the User makes a legitimate objection;
                        </li>
                        <li>
                            in case of voluntary and optional consents (Art. 6 (1) (a) RODO) and Art. 10 of the Act of 18.07.2002 on the provision of electronic services and Art. 172 of the Act of 16.07.2004. - Telecommunications Law) data will also be processed for marketing purposes consisting, for example, in informing about products sold/services provided by the Administrator /Operator, also on the basis of separate consents given by e-mail or during a telephone conversation - until the consent is withdrawn.
                        </li>
                    </ol>
                    <p className={style.text}>After the expiration of the processing period, the Personal Data is immediately deleted or anonymized.</p>
                </li>
                <li className={style.extra_margin}>
                    The Administrator will process the following Personal Data provided by Platform Users:
                    <ol className={style.second_list}>
                        <li>company name,</li>
                        <li>first and last name ( among others, of the person authorized to represent them),</li>
                        <li>number for tax and statistical registration purposes,</li>
                        <li>number of entry in the register of entities conducting business activities,</li>
                        <li>correspondence address,</li>
                        <li>e-mail address,</li>
                        <li>telephone number.</li>
                    </ol>
                </li>
                <li>
                    With regard to Personal Data concerning the User&apos;s visits to the Platform, the Administrator may (when required and having obtained the User&apos;s consent to do so) obtain data concerning the devices and networks used by the User in order to access the Supplier&apos;s Commercial Offer posted on the Platform and the Goods placed by the User in the Shopping Cart, as well as to view and execute submitted Orders. This data may include: the User&apos;s IP address, login data, type and version of web browser, types and versions of plug-ins used by web browsers, operating system and platform, advertising ID, information about visits, including the URL of the site where the link leading to the Platform was clicked, products searched for or browsed, download errors, time of visit to specific pages, interaction with other sites. This data is obtained by the Administrator, who obtains this data in particular through cookies and other technologies.
                </li>
                <li>
                    Provision of Personal Data referred to in sec. 3. is voluntary, but necessary for the Administrator/Operator to provide Services on the Platform or to conclude an Agreement for the sale of Goods. Each time, the scope of the required data is also indicated in the Platform Terms and Conditions and prior to the provision of a particular Service or the conclusion of Contracts on the Platform website.
                </li>
                <li>
                    In connection with the performance of Services and the sale of Goods on the Platform, Personal Data may be disclosed to the following entities:
                    <ol className={style.second_list}>
                        <li>Suppliers of</li>
                        <li>
                            in the case of a User who uses the method of delivery of the Goods by courier service, the Administrator shall make the collected Personal Data of the User available to the selected carrier or postal operator executing shipments on behalf of the Administrator, to the extent and for no longer than it is necessary in order to execute the order and make delivery of the ordered goods;
                        </li>
                        <li>
                            to the Administrator&apos;s service providers (in particular, with regard to IT services, technical issues, accounting and financial services and payments);
                        </li>
                        <li>
                            persons cooperating with the Administrator within the Platform on the basis of an authorization issued by the Administrator;
                        </li>
                        <li>state authorities, the prosecutor&apos;s office, the police, when required by law.</li>
                    </ol>
                </li>
                <li>
                    The Administrator informs that the transfer of Personal Data to external recipients will take place when:
                    <ol className={style.second_list}>
                        <li>it is necessary to use the services of an external entity,</li>
                        <li>it is necessary for contracts concluded with external entities,</li>
                        <li>it is necessary for the proper execution and delivery of the Goods;</li>
                        <li>
                            it is necessary for analytical purposes, including the optimization of the Platform and the Goods provided by the Administrator;
                        </li>
                        <li>it is required by generally applicable law;</li>
                        <li>it is necessary for the defense of claims or rights of the Administrator, including in connection with pending litigation;</li>
                        <li>a circumstance has occurred that constitutes a threat to life, health, property or safety.</li>
                    </ol>
                </li>
                <li>
                    Personal Data collected is not transferred to third countries (i.e. outside the European Economic Area) or any international organization.
                </li>
                <li>
                    Personal Data will be processed by automated means including profiling. Automated decision-making will take place on the basis of verification of the history of the User&apos;s browsing - Goods available on the Platform. In turn, the consequence of such processing of Personal Data will be to provide Users with content related to the use of the Platform and to customize such content to the individual preferences of the User.
                </li>
            </ol>
            <h2 className={style.header}>III. COOKIES AND USAGE DATA</h2>
            <ol className={style.list}>
                <li>
                    Cookies are text files placed by the server on the device on which the Browser operates. Cookies are IT data, in particular text files, which are stored on the User&apos;s terminal device (e.g. in the memory of a computer) and are intended for the use of the Platform.
                </li>
                <li>
                    The Administrator uses cookies to improve the quality of the Platform&apos;s functioning, to match its content to the User&apos;s preferences, as well as to optimize the User&apos;s use of the Platform. Through cookies it is also possible to maintain a login session on the Platform.
                </li>
                <li>
                    The Service Provider processes the data contained in cookies when visitors use the Platform website for the following purposes:
                    <ol className={style.second_list}>
                        <li>identifying Service Recipients as logged in on the Platform and showing that they are logged in;</li>
                        <li>remembering Goods added to the Shopping Cart for the purpose of submitting an Order Form;</li>
                        <li>to remember data from completed Order Forms or login data on the Platform;</li>
                        <li>to adapt the Supplier&apos;s Commercial Offer to the User&apos;s individual preferences;</li>
                        <li>to optimize the use of the Platform;</li>
                        <li>
                            to keep anonymous statistics showing how the Platform is used and to research Users&apos; needs, excluding personal identification of the User.
                        </li>
                    </ol>
                </li>
                <li>
                    By default, most web browsers available on the market accept the storage of Cookies by default. It is possible to determine the conditions for the use of Cookies through the settings of your own web browser. This means that you can, for example, partially restrict (e.g. temporarily) or completely disable the ability to save Cookies on your computer - in the latter case, however, this may affect some of the functionality of the Platform (e.g. it may not be possible to follow the order path through the Order Form).
                </li>
                <li>
                    Your browser&apos;s settings regarding Cookies are relevant to your consent to the use of Cookies by our Platform - in accordance with the regulations, such consent can also be given through your browser settings.
                </li>
                <li>
                    Detailed information on how to change the settings for Cookies and how to delete them yourself in the most popular web browsers is available in the help section of your web browser and on the following pages (just click on the link):
                    <ol className={style.second_list}>
                        <li>
                            in Chrome browser <a href='https://support.google.com/chrome/answer/95647?hl=pl'>[link]</a>
                        </li>
                        <li>
                            in Firefox browser <a href='https://support.mozilla.org/pl/kb/ciasteczka'>[link]</a>
                        </li>
                        <li>
                            in the Microsoft Edge browser
                            <a href='https://support.microsoft.com/pl-pl/microsoft-edge/usuwanie-plik%C3%B3w-cookie-w-przegl%C4%85darce-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09'>
                                [link]
                            </a>
                        </li>
                        <li>
                            in the Opera browser <a href='https://help.opera.com/pl/latest/web-preferences/#cookies'>[link]</a>
                        </li>
                        <li>
                            in the Safari browser <a href='https://support.apple.com/pl-pl/guide/safari/sfri11471/mac'>[link]</a>
                        </li>
                        <li>
                            in Samsung Internet browser
                            <a href='https://www.samsung.com/uk/support/mobile-devices/using-the-samsung-internet-app/'>[link]</a>
                        </li>
                    </ol>
                </li>
                <li>
                    The Administrator also collects exploitation data (so-called logs - IP address, domain), which are stored for an indefinite period of time and used only to generate statistics to help administer the Platform. This data is aggregate and anonymous, i.e. it does not contain characteristics that identify visitors to the Platform. The logs are not disclosed to third parties.
                </li>
            </ol>
            <h2 className={style.header}>IV. NEWSLETTER</h2>
            <ol className={style.list}>
                <li>
                    The email address provided by the User is used to send the newsletter. The User&apos;s confirmation is required here, which consists of the User&apos;s voluntary consent, as the owner of the e-mail address, to receive the newsletter.
                </li>
                <li>
                    The User may terminate the newsletter service agreement at any time by deactivating the subscription. Unsubscribing from the newsletter is done by sending a statement of intent to biuro@dentametr.pl or through the appropriate link in the footer of each message sent within the newsletter.
                </li>
            </ol>
            <h2 className={style.header}>
                V. RIGHT OF CONTROL, ACCESS TO THE CONTENT OF YOUR DATA, OBTAIN A COPY OF THE CORRECTION OF DATA, MAKE AN OBJECTION
            </h2>
            <ol className={style.list}>
                <li>
                    In accordance with the provisions of the RODO, the User has the following rights related to the control of the processing of Personal Data:
                    <ol className={style.second_list}>
                        <li>
                            The right to access the content of your Personal Data (including, but not limited to, obtaining information as to what type of Personal Data is processed by the Administrator, obtaining a copy of your Personal Data);
                        </li>
                        <li>The right to request correction, updating or rectification of Personal Data;</li>
                        <li>The right to request deletion of Personal Data if it is incomplete,</li>
                        <li>
                            The right to request deletion of Personal Data if it is incomplete, outdated, untrue or has been collected in violation of the law or is unnecessary for the purpose for which it was collected; notwithstanding the above, the User has the option to delete the Account on the Platform (however, this is not equivalent to deletion of Personal Data);
                        </li>
                        <li>
                            The right to lodge a complaint to the supervisory authority dealing with the protection of personal data - i.e. the President of the Office for Personal Data Protection (e.g. in the event that the processing of Personal Data is deemed to violate the provisions of the RODO or other regulations concerning the protection of Personal Data);
                        </li>
                        <li>The right to request restriction of processing of Personal Data;</li>
                        <li>
                            The right to object to the processing of Personal Data if the processing is based on the legitimate interest of the Administrator or for direct marketing purposes.
                        </li>
                    </ol>
                </li>
                <li>
                    If Personal Data is processed on the basis of consent, the User has:
                    <ol className={style.second_list}>
                        <li>The right to withdraw consent at any time;</li>
                        <li>The right to portability of Personal Data.</li>
                    </ol>
                </li>
                <li>
                    In order to exercise their rights, the User may contact the Administrator through the communication channels indicated in sec. VII: &quot;Contact Information.&quot;
                </li>
                <li>
                    If a violation of the protection of Personal Data is noted, the User has the right to complain to the supervisory authority, i.e. the President of the Office for Personal Data Protection.
                </li>
                <li>
                    The Administrator reserves the right not to comply with the User&apos;s requests for deletion, modification or change of the scope of processing of Personal Data in case of violation of the rules and regulations of the Platform or in case of violation of generally applicable laws. Keeping the Personal Data unchanged will be aimed at clarifying the circumstances of the aforementioned violations and establishing responsibility for them.
                </li>
            </ol>
            <h2 className={style.header}>VI. SECURITY OF PERSONAL DATA</h2>
            <ol className={style.list}>
                <li>
                    The Platform may contain links to other websites. When you go to other sites, please read the privacy policy established there. Our Privacy Policy applies only to this Platform.
                </li>
                <li>
                    The Administrator shall apply technical and organizational measures to ensure the protection of the processed personal data appropriate to the risks and categories of protected data, and in particular shall protect the data from being made available to unauthorized persons, from being taken by an unauthorized person, from being processed in violation of applicable regulations, and from being altered, lost, damaged or destroyed.
                </li>
                <li>
                    The Administrator uses modern technical means available on the market to prevent acquisition and modification by unauthorized persons of Personal Data sent electronically (e.g. SSL certificate - Secure Socket Layer , protection of Personal Data from unauthorized access; frequent updates of software through frequent updates; access to an individual Account only after logging in with a login and password; passwords are secret and are stored in the database as so-called hashes; encryption of Personal Data in the database).
                </li>
            </ol>

            <h2 className={style.header}>VII. CONTACT INFORMATION</h2>
            <p className={style.text}>
                If you have any questions, requests or appeals regarding your Personal Data or wish to exercise a specific right, you may contact the Administrator at biuro@dentametr.pl.
            </p>
            <h2 className={style.header}>VIII. CHANGES TO THE PRIVACY POLICY</h2>
            <p className={style.text}>
                The Administrator strives to ensure that this Policy is up-to-date and updated in the event of changes in the law, judicial decisions, guidelines of the authorities responsible for supervising the processing of Personal Data, introduction of Codes of Good Practice (if the Administrator will be bound by such codes), changes in technology, methods, purposes or legal basis for the processing of Personal Data.
            </p>
        </LegalTermWrapper>
    );
};

export default PrivacyPolicy;