import { useTranslation } from 'react-i18next';

import { CONTACT_EMAIL } from '../../../../constants/misc';
import Form from '../Form';

import style from './Main.module.scss';

const Main = () => {
  const { t } = useTranslation('page');
  return (
    <div className={style.container}>
      <div className={style.left}>
        <p className={style.subtitle}>{t('contact.main.subTitle')}</p>
        <h1 className={style.title}>{t('contact.main.title')}</h1>
        <p className={style.text}>
          {t('contact.main.text')}
        </p>
        <a
          className={style.link}
          href={`mailto:${CONTACT_EMAIL}`}
        >
          {CONTACT_EMAIL}
        </a>
      </div>
      <Form />
    </div>
  );
};

export default Main;
