import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ReactComponent as Logo } from 'assets/img/avalio-logo-dark.svg';
import { LanguageSwitcher } from 'components/index';

import { CONTACT_EMAIL } from '../../../constants/misc';

import style from './Footer.module.scss';

const Footer = () => {
  const { t } = useTranslation('common');
  return (
    <footer className={style.container}>
      <div className={style.contact}>
        <h4 className={style.title}>
          {t('footer.title')}
          <br />
          {t('footer.text1')}{' '}
          <a
            href={`mailto:${CONTACT_EMAIL}`}
            className={style.mailLink}
          >
            {CONTACT_EMAIL}
          </a>
        </h4>
      </div>
      <div className={style.box}>
        <Link to={'/'}>
          <Logo className={style.logo} />
        </Link>
        <div className={style.list}>
          <p>Copyright © {new Date().getFullYear()} by Avalio</p>
          <LanguageSwitcher position='top' />
          <Link
            to={'/privacy-policy'}
            className={style.link}
          >
            {t('navigation.privacyPolicy')}
          </Link>
          <Link
            to={'/statute'}
            className={style.link}
          >
            {t('navigation.statute')}
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
