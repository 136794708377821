import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OutsideClickHandler from 'react-outside-click-handler';
import classNames from 'classnames';
import dayjs from 'dayjs';

import style from './LanguageSwitcher.module.scss';

export const languageKey = 'i18nextLng';

import england from 'assets/flags/england.png';
import poland from 'assets/flags/poland.png';

const languages = [
  {
    id: 'pl',
    label: 'Polski',
    flag: poland,
  },
  {
    id: 'en',
    label: 'English',
    flag: england,
  }
];

const LanguageSwitcher = ({ position = 'bottom', align = 'left', className }) => {
  const { i18n, t } = useTranslation();
  const [isSelectionActive, setIsSelectionActive] = useState(false);

  const currentLanguage = useMemo(() => languages.find((lang) => lang.id === i18n.language) || languages[0], [i18n.language]);

  const handleChangeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    dayjs.locale(lang);
    setIsSelectionActive(false);
  };

  const handleToggleSelectionActive = () => {
    setIsSelectionActive((prev) => !prev);
  };

  const popupClasses = classNames(style.popup, {
    [style.active]: isSelectionActive,
    [style[position]]: position,
    [style[align]]: align,
  });

  return (
    <div className={style.languageSelector}>
      <OutsideClickHandler
        onOutsideClick={() => {
          setIsSelectionActive(false);
        }}
        display={'contents'}
      >
        <div
          className={classNames(style.currentLanguage, className)}
          onClick={handleToggleSelectionActive}
        >
          <img src={currentLanguage.flag} alt='flag' className={style.flag} />
          <div className={style.name}>{currentLanguage.label}</div>
        </div>

        <div className={popupClasses}>
          <p className={style.text}>{t('common:action.selectLanguage')}</p>

          <div className={style.list}>
            {languages.map((lang) => (
              <div
                className={classNames(style.item, lang.id === currentLanguage.id && style.current)}
                onClick={() => handleChangeLanguage(lang.id)}
                key={lang.id}
              >
                <img src={lang.flag} alt='flag' className={style.flag} />
                <div className={style.name}>{lang.label}</div>
              </div>
            ))}
          </div>
        </div>
      </OutsideClickHandler>
    </div>
  );
};

export default LanguageSwitcher;
