import { Link } from 'react-router-dom';

import LegalTermWrapper from 'components/layout/LegalTermWrapper';

import style from 'src/components/layout/LegalTermWrapper/LegalTermWrapper.module.scss';
import { CONTACT_EMAIL } from 'src/constants/misc';

const Statute = () => {
  return (
    <LegalTermWrapper
      pageTitle={'Terms and Conditions'}
      documentTitle={'Terms and Conditions of the \"Avalio\" Platform'}
    >
      <h2 className={style.column_header}>
        <span>§ 1 </span>
        <span>Introductory provisions</span>
      </h2>
      <ol className={style.list}>
        <li>
          The owner and Operator of the Platform is CBM 16 Limited Liability Company with its registered seat in Jasionka, address: 36-002 Jasionka 954 E, entered in the Register of Entrepreneurs of the National Court Register by the District Court in Rzeszów, XII Economic Department of the National Court Register under KRS number: 0000809744, NIP: 5170402504, REGON: 384670823 with a share capital of PLN 10,000.
        </li>
        <li>
          These Regulations set forth the rules for the use of the Platform, as well as the principles and procedures for concluding Distance Selling Contracts via the Platform.
        </li>
        <li>
          Users of the Platform are obliged to respect the law, the rules of social coexistence and the good name and legal interests of the Operator and other Users.
        </li>
        <li>
          The Operator reserves the right to remove the Account or temporarily block the possibility of using the content and services for Users who violate or do not respect the provisions of the Regulations, as well as those who violate the interests of the Operator or other Users in any way. Assessment of the occurrence of the above undesirable behavior is at the discretion of the Operator.
        </li>
        <li>
          The Platform and the Regulations are addressed exclusively to entrepreneurs and companies conducting business in the Republic of Poland, excluding consumers.
        </li>
      </ol>
      <h2 className={style.column_header}>
        <span>§ 2 </span>
        <span>Terms and definitions</span>
      </h2>
      <ol className={style.list}>
        <li>Whenever these Regulations refer to:</li>
        <ol className={style.second_list}>
          <li>
            <strong>Personal Data</strong> - it should be understood as information provided voluntarily and independently by the User, during the registration process, and processed by the Platform for the purpose specified in the Regulations; detailed information regarding the collection and processing of Users&apos; Personal Data, including the Privacy Policy, is available at <Link to={'/'}>avalio.io</Link>.
          </li>
          <li>
            <strong>Supplier</strong> – it should be understood as a distributor of medical devices or pharmaceutical products for dental offices, providing Goods directly to Users.
          </li>
          <li>
            <strong>Warranty Document</strong> – it should be understood as a document specifying the terms of use of the warranty conditions of the purchased Goods.
          </li>
          <li>
            <strong>Invoice</strong> – it should be understood as a document confirming the execution of the Sales Agreement, which contains detailed information about the completed Order.
          </li>
          <li>
            <strong>Registration Form</strong> – it should be understood as an interactive form available on the Platform, enabling the creation of an Account, requiring the User to provide Personal Data.
          </li>
          <li>
            <strong>Order Form</strong> - it should be understood as an interactive form available on the Platform that allows the submission of an individualized Order, specifying the terms of the Sales Agreement, including the method of payment.
          </li>
          <li>
            <strong>Password</strong> – should be understood as a string of characters consisting of at least 8 characters including numbers and letters, individually created by the User, the provision of which is required during the registration process and enables further use of the Platform and access to the Account.
          </li>
          <li>
            <strong>Account</strong> – should be understood as requiring registration on the Platform website and providing the necessary data, an individualized set of ICT resources, enabling the User, among other things, to use the Platform and place an Order with the Operator.
          </li>
          <li>
            <strong>Shopping cart</strong> – should be understood as an element of the Platform&apos;s software, in which the User&apos;s chosen Goods for purchase are visible, giving the possibility to determine and modify the details of the Order, in particular the quantity of the Goods.
          </li>
          <li>
            <strong>Newsletter</strong> - should be understood as an electronic service provided by the Operator, sending information about new offers and promotions on the Platform to the User&apos;s e-mail address.
          </li>
          <li>
            <strong>Entrepreneur</strong> – should be understood as a User who is a natural person, legal entity or organizational unit without legal personality, and to whom the law grants legal capacity, using the Platform for purposes related to business or professional activity.
          </li>
          <li>
            <strong>Regulations</strong> - should be understood as these Regulations of the Platform.
          </li>
          <li>
            <strong>Platform</strong> – should be understood to mean the Internet platform operating at the Internet address <Link to={'/'}>avalio.io</Link> operated by the Operator.
          </li>
          <li>
            <strong>Merchandise</strong> - should be understood as articles presented by the Operator in the Platform, in the form of pharmaceutical and medical products for dental offices, which can be ordered through the Platform.
          </li>
          <li>
            <strong>Sales Contract</strong> - should be understood as the contract of sale of Goods concluded between the User and the Operator via the Platform.
          </li>
          <li>
            <strong>User</strong> - should be understood as a buyer running a dental practice in the form provided by law, being an Entrepreneur, using the Platform.
          </li>
          <li>
            <strong>Order</strong> - should be understood as the User&apos;s statement aiming directly at the conclusion of the Sales Contract of Goods.
          </li>
        </ol>
        <li>
          Whenever the definitions above use the singular number to denote a designated action, person or thing, these definitions shall apply accordingly to the plural number, and when the plural number is used, these definitions shall apply accordingly to the singular number.
        </li>
        <li>Each User is obliged to comply with the provisions of the Rules and Regulations and to familiarize himself with their contents.</li>
      </ol>
      <h2 className={style.column_header}>
        <span>§ 3 </span>
        <span>Registration</span>
      </h2>
      <ol className={style.list}>
        <li>
          In order to use the Platform, the User is obliged to register. Registration enables the creation of an Account.
        </li>
        <li>
          In order to register, the User must:
          <ol className={style.second_list_abc}>
            <li>have full legal capacity;</li>
            <li>have an e-mail address;</li>
            <li>
              have an entry in the Central Register of Doctors and Dentists or be employed in a dental practice. Registration of the User can be done only via the Internet.
            </li>
          </ol>
        </li>
        <li>
          The User completes the Registration Form located at <a href={'https://app.avalio.io/register'}>https://app.avalio.io/register</a> by providing the data required therein.
        </li>
        <li>
          In order to register, the User provides the following data:
          <ol className={style.second_list_abc}>
            <li>Name of the company and the name of the person authorized to represent it,</li>
            <li>Possessed number for tax and statistical registration purposes,</li>
            <li>E-mail address,</li>
            <li>Telephone number,</li>
            <li>Individual Password required to log in to the Platform each time.</li>
          </ol>
        </li>
        <li>
          In the case of a User who is a legal entity or an organizational unit without legal personality, in addition to providing the data referred to in § 3.5 of the Regulations, the first and last name of the natural person performing the registration shall be provided.
        </li>
        <li>
          The User will receive notification of account approval by e-mail from the Operator after completing the Registration Form.
        </li>
        <li>
          The Operator reserves the right to request additional statements and documents confirming the conduct of business by entities having their registered office, registered business or actual place of business abroad in Poland. Information regarding additional documents, which the entities indicated in the preceding sentence will be required to send, will be included in an e-mail message sent by the Platform after the User completes the Registration Form.
        </li>
        <li>
          The email referred to in paragraph 7 shall contain a summary of the most important provisions of these Regulations. The summary does not limit the scope of these Terms and Conditions in any way, receipt of the email with the summary does not release the User from the obligation to read the entire Terms and Conditions and agree to them. This email will also contain a one-time password for the first login and a link to the login page. After receiving the e-mail with the password, click on the provided link (website address) to log in to the Platform for the first time. After logging into the Platform, the User will be able to change the Password at any time.
        </li>
        <li>
          If the User loses the Password, the User may request the Operator to resend the one-time password. This allows the User to log in to the Platform and then enter the target Password.
        </li>
        <li>
          By clicking the appropriate field, the User agrees to begin using the Platform. Starting and using the Platform requires acceptance of the Terms and Conditions.
        </li>
        <li>If the User does not agree with the Terms of Use, he/she may not use the Platform.</li>
        <li>
          The User shall be held fully responsible for incorrect data entered in the Registration Form and for incorrect data provided in the Order Form submitted via the Platform.
        </li>
        <li>
          The User shall keep the Account data up-to-date. It is forbidden to provide incomplete or false data.
        </li>
        <li>
          The User accepts that the Operator shall have access to the data and information provided by the User on the Platform.
        </li>
        <li>
          In a situation where the User&apos;s data requires additional verification, or in the event of reasonable concerns regarding the security of the Account or Orders, especially regarding unauthorized takeover of the Account by another person, the Operator may:
          <ol className={style.second_list}>
            <li>
              make the use of the Platform conditional on the User&apos;s confirmation of their credibility with appropriate documents,
            </li>
            <li>suspend the User&apos;s Account for a definite or indefinite period of time.</li>
          </ol>
          Contact and verification may also take place by the Operator contacting the User outside the Platform, including by telephone or mail.
        </li>
        <li>
          The User is entitled to have only one active Account on the Platform. If the Operator becomes aware that the User is the holder of more than one Account, the Operator may suspend them until the matter is clarified. This right may also be exercised by the Operator if a third party impersonates a User or otherwise interferes with the operation of the Platform. Upon termination of the aforementioned circumstances, the Operator shall lift the said restrictions applied to the User.
        </li>
      </ol>
      <h2 className={style.column_header}>
        <span>§ 4 </span>
        <span>Platform</span>
      </h2>
      <ol className={style.list}>
        <li>
          The Operator uses the mechanism of &quot;cookies&quot; files, which, during the User&apos;s use of the Platform, are saved by the Operator&apos;s server on the hard drive of the User&apos;s final device. The use of &quot;cookies&quot; is aimed at the correct operation of the Platform on the Users&apos; terminal devices. This mechanism does not damage the User&apos;s terminal device and does not cause configuration changes in the User&apos;s terminal devices or in the software installed on these devices. Each User may disable the &quot;cookies&quot; mechanism in the web browser of his/her end device. The Operator points out that disabling &quot;cookies&quot; may, however, make it difficult or impossible to use the Platform.
        </li>
        <li>
          Minimum technical requirements to use the Platform for desktop devices:
          <ol className={style.second_list_abc}>
            <li>Computer with Internet access.</li>
            <li>Web browser with a graphical interface and cookies enabled.</li>
          </ol>
        </li>
        <li>
          The Platform uses geolocation of Users via the GoogleMaps plug-in. Geolocation is limited only to the designation of the locality from which the User uses the Platform.
        </li>
        <li>
          The Operator shall use its best efforts to ensure that the transmission of data via the Internet as part of the use of the Platform is secure, i.e. that the transmitted information is transmitted with confidentiality, integrity and completeness of the transmitted data.
        </li>
        <li>
          The Operator declares that regardless of the measures taken by it to secure the Platform, due to the public nature of the Internet, Users should take into account the risk of obtaining and modifying Users&apos; data by unauthorized persons. Users should also, in order to increase the security of their equipment and data, apply appropriate technical measures to minimize the aforementioned risks through the use of anti-virus and identity protection programs for Internet users.
        </li>
        <li>
          Any unusual signs of the Platform&apos;s functioning, in particular, a different appearance of the Platform (graphic layout), unprecedented messages, images, without prior information issued by the Operator to the Users regarding the possibility of such events, may mean an attempt of unlawful interception of the Password or other unlawful actions of third parties. Under such circumstances, the Operator recommends immediate discontinuation of use of the Platform.
        </li>
        <li>
          The Operator is not responsible for technical problems or technical limitations in the computer equipment or Internet connection used by the User, which prevent the User from using the Platform.
        </li>
      </ol>
      <h2 className={style.column_header}>
        <span>§ 5 </span>
        <span>Orders</span>
      </h2>
      <ol className={style.list}>
        <li>
          A User may place an Order for Goods if they have logged into their Account on the Platform, in accordance with the rules described in the Regulations.
        </li>
        <li>
          Placing an Order requires, after selecting the Goods on the Platform that the User is interested in purchasing, following the instructions on the Platform.
        </li>
        <li>
          The Operator does not guarantee the constant availability of the Goods offered in the Platform, and shall not be liable to the User for their lack of availability.
        </li>
        <li>
          The Operator shall not be liable to the User for any discrepancies that may arise between the visualization of the promoted Goods in the Platform and the actual Goods, particularly but not exclusively in terms of color, dimensions and price constancy of the Goods.
        </li>
        <li>
          The prices of the Goods listed in the Platform come from Suppliers who, in their quotations, specify whether the prices they provide are gross prices and include applicable VAT.
        </li>
        <li>
          The Operator shall not be liable to the User for any malfunctions of the Platform, including the User&apos;s service in the transactions of purchase of the Goods concluded by the User.
        </li>
      </ol>
      <h2 className={style.column_header}>
        <span>§ 6 </span>
        <span>Process of concluding a Sales Contract</span>
      </h2>
      <ol className={style.list}>
        <li>Information about the Goods placed on the Platform constitutes an invitation to conclude a Sales Agreement.</li>
        <li>
          The Operator does not conduct sales of the Goods. All transactions concluded via the Platform are concluded directly with the Providers.
        </li>
        <li>
          In order to conclude a Sales Agreement, the User must log in to their Account on the Platform, and then make a selection of Goods, pricing with selected Suppliers, and then order the selected products via the Order Form available on the Platform.
        </li>
        <li>
          In the course of placing an Order, the User has the possibility to modify the data concerning the ordered Goods until the moment of confirming the will to purchase with the button &quot;proceed to the execution of the order&quot;.
        </li>
        <li>
          After the User fills in all required fields, the Order will be displayed in the Order summary:
          <ol className={style.second_list}>
            <li>the subject of the Order,</li>
            <li>the unit and total price of the ordered Goods along with the cost of delivery,</li>
            <li>the selected payment method,</li>
            <li>selected method of delivery,</li>
            <li>delivery time.</li>
          </ol>
        </li>
        <li>
          After placing the Order, the User sees information in his/her account under the &quot;Orders&quot; tab, which includes the final confirmation of the ordered Goods. The Sales Contract is considered to be concluded when the Supplier confirms the Order.
        </li>
        <li>
          Recording, protection and disclosure of the Order data and other terms of the Sales Agreement shall be made via e-mail.
        </li>
      </ol>
      <h2 className={style.column_header}>
        <span>§ 7 </span>
        <span>Delivery</span>
      </h2>
      <ol className={style.list}>
        <li>
          Delivery of the ordered Goods is limited to the territory of the Republic of Poland, unless arrangements with the Supplier indicate otherwise.
        </li>
        <li>Delivery is made to the address indicated by the User through the Supplier.</li>
        <li>Delivery costs depend on the ordered Goods and are shown in the Order summary.</li>
        <li>
          If the Goods are sent by the Supplier through a carrier, the User is obliged to examine the shipment at the time and in the manner usual for shipments of this type. If he finds that loss or damage to the Goods occurred during shipment, the User is obliged to perform all actions necessary to determine the carrier&apos;s liability.
        </li>
      </ol>
      <h2 className={style.column_header}>
        <span>§ 8 </span>
        <span>Prices and payment methods</span>
      </h2>
      <ol className={style.list}>
        <li>Prices come from Suppliers and Operator has no influence on them.</li>
        <li>Prices are quoted on the Platform in Polish currency.</li>
        <li>Prices are presented in the form of gross prices or net prices depending on the pricing method adopted by the Supplier.</li>
        <li>Available payment methods are determined by the Supplier at the time of pricing.</li>
        <li>The Provider does not act as an intermediary or a party to the transaction.</li>
        <li>
          Delivery of the Invoice to the User is possible through the Platform or in another form agreed upon with the Provider.
        </li>
      </ol>
      <h2 className={style.column_header}>
        <span>§ 9 </span>
        <span>Warranty and complaints</span>
      </h2>
      <ol className={style.list}>
        <li>
          Goods presented on the Platform may be covered by a manufacturer&apos;s or distributor&apos;s warranty. The detailed terms of the warranty and its duration are then stated in the Warranty Document issued by the guarantor and attached to the Goods. The Operator does not provide a warranty for the Goods sold.
        </li>
        <li>
          In the event that the delivered Goods are found to be inconsistent with the Order, incompleteness of the Goods or other objections related to the delivery, the User shall have the right to file a complaint directly with the Supplier.
        </li>
        <li>
          In the cases indicated in paragraph 2, the User is obliged to inform the Provider by email, telephone or via the Platform, indicating the circumstances concerning the subject of the complaint, in particular the type and date of the irregularity, contact details and possible photographic documentation.
        </li>
        <li>The Provider&apos;s warranty liability to the User is excluded.</li>
      </ol>
      <h2 className={style.column_header}>
        <span>§ 10 </span>
        <span>Right of withdrawal</span>
      </h2>
      <ol className={style.list}>
        <li>
          A User who is a natural person concluding a Sales Agreement directly related to his/her business activity, when it follows from the content of the Sales Agreement that it does not have for that person a professional character resulting in particular from the subject matter of his/her business activity, made available on the basis of the provisions on the Central Register and Information on Business Activity, has the right to withdraw from the Sales Agreement within 14 days, without giving any reason and without incurring costs, except as indicated in this paragraph below.
        </li>
        <li>The period for withdrawal from the Sales Agreement shall begin on the date of delivery of the Goods to the User.</li>
        <li>
          The User, referred to in paragraph 1, may withdraw from the Sales Agreement by sending a statement of withdrawal from the Sales Agreement to the Supplier via email. The statement may be made on a form, the specimen of which is attached as Appendix No. 1 to the Terms of Use, or in any other manner from which the will to withdraw from the Sales Agreement is clearly evident.
        </li>
        <li>
          The User who has withdrawn from the Sales Agreement should return the Goods to the address of [*Provider/Operator], sending or delivering them in a form protecting them from damage, at his/her own expense.
        </li>
        <li>
          The User shall be liable for any diminution in the value of the Goods resulting from the use of the Goods beyond what is necessary to ascertain the nature, characteristics and functioning of the Goods.
        </li>
      </ol>
      <h2 className={style.column_header}>
        <span>§ 11 </span>
        <span>Obligations of the User</span>
      </h2>
      <ol className={style.list}>
        <li>
          The User is obliged to use the Platform in a manner consistent with the applicable laws, these Regulations, as well as the rules of social coexistence, including the general rules of Internet use, and with respect for the rights of the Operator and third parties.
        </li>
        <li>
          Users are obliged not to post unlawful or offensive content on the Platform, content whose publicity has been disabled, content that violates the personal rights of third parties, incitement to commit a crime, or statements that are vulgar or disrespectful to the dignity of the Platform and third parties.
        </li>
        <li>
          Users are obliged to immediately notify the Operator of any violation (or threatened violation) of their rights or the rights of third parties, in connection with the use of the Platform. All notifications should be reported to the Operator via e-mail to the following e-mail address: <a href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</a>.
        </li>
      </ol>
      <h2 className={style.column_header}>
        <span>§ 12 </span>
        <span>Liability</span>
      </h2>
      <ol className={style.list}>
        <li>
          The User uses the Platform voluntarily, at his/her own risk, and therefore the Operator&apos;s liability for any damages arising in connection with the use of the Platform, in particular its non-functioning and malfunctioning, is excluded to the fullest extent permitted by law.
        </li>
        <li>
          The Operator does not guarantee that the Platform will be free of errors, and that it will operate without interruption. The warranties contained herein are the sole and exclusive warranties that Users will receive in connection with the provision of the Platform.
        </li>
        <li>
          The Operator shall not be liable for any interruption in access to the Service caused through no fault of the Operator, including cases of data breaches or loss in data processing, defects in the security system, or the undesirable effects of viruses or other types of malware while using the Platform.
        </li>
        <li>
          The Operator is not responsible for the use of the Platform by Users in a manner contrary to the provisions of these Terms of Use, in particular, the Operator is not responsible for damages caused as a result of false data and information provided by the User.
        </li>
        <li>
          The Operator shall not be responsible for limitations or technical problems in the ICT systems used by Users&apos; devices, which prevent or limit Users&apos; use of the Platform.
        </li>
        <li>
          The Operator is not responsible for the consequences resulting from the use of the login and Password by third parties, which may consist in the execution of instructions submitted to the Platform or through the Platform by an unauthorized person.
        </li>
        <li>
          The Operator&apos;s liability to the User, regardless of its legal basis, is limited and the Operator shall not be liable for lost profits to the User.
        </li>
      </ol>
      <h2 className={style.column_header}>
        <span>§ 13 </span>
        <span>Newsletter</span>
      </h2>
      <ol className={style.list}>
        <li>
          The newsletter subscription service is provided free of charge by the Platform to Users who voluntarily agree to it.
        </li>
        <li>
          Subscription to the newsletter is requested through the appropriate form available on the Platform, providing one&apos;s e-mail address and confirming with the &quot;order&quot; button.
        </li>
        <li>
          Upon activation of the &quot;order&quot; button, a newsletter service agreement is concluded between the User and the Platform for an indefinite period of time.
        </li>
        <li>
          The User may terminate the newsletter service agreement at any time by deactivating the subscription. Unsubscribing from the newsletter is done by sending a declaration of intent to <a href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</a> via the appropriate link located in the footer of each message sent as part of the newsletter.
        </li>
        <li>
          Consent to receive commercial information sent by means of electronic communication is voluntary and the User may withdraw it at any time.
        </li>
      </ol>
      <h2 className={style.column_header}>
        <span>§ 14</span>
        <span>Complaint procedure regarding the functioning of the Platform</span>
      </h2>
      <ol className={style.list}>
        <li>
          Any complaints regarding the malfunctioning of the Platform should be reported to the Operator via e-mail to: <a href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</a>.
        </li>
        <li>
          The complaint submission should include, in each case, the model of the device on which the problem with the functioning of the Platform occurred.
        </li>
      </ol>
      <p className={style.text}>
        Correctly reported complaints will be considered no later than within 7 working days counted from the moment of receipt of information about the irregularity. The Operator will inform the User via e-mail about the method of processing the complaint. If the data or information provided in the complaint needs to be supplemented, the Operator shall request the User to supplement it before considering the complaint. The time for the User to provide additional explanations extends the period of consideration of the complaint.
      </p>
      <h2 className={style.column_header}>
        <span>§ 15 </span>
        <span>Processing of Personal Data</span>
      </h2>
      <p className={style.text}>
        Detailed information regarding the collection and processing of Users&apos; Personal Data, including the Privacy Policy, is available at the <Link to='/policy-privacy'>link</Link>.
      </p>
      <h2 className={style.column_header}>
        <span>§ 16 </span>
        <span>Change of Terms of Use</span>
      </h2>
      <ol className={style.list}>
        <li>
          The Operator may change these Terms and Conditions for important legal or technical reasons. The Operator will inform about the change of the Terms and Conditions in a message displayed on the Platform, 7 (seven) days before the new Terms and Conditions take effect. During this time, the User should accept the new provisions of the Terms and Conditions or refuse to accept them by sending an appropriate email to <a href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</a>. Refusal to accept the changes to the Regulations is the same as deletion of the Account from the Platform.
        </li>
        <li>
          If the User does not submit a statement of refusal of acceptance before the expiration of the deadline indicated above, he/she shall be deemed to have accepted the amended Terms and Conditions without reservation upon the expiration of the deadline.
        </li>
        <li>
          The amendment to the Regulations shall not affect Orders already placed and Sales Agreements concluded, executed or performed.
        </li>
      </ol>
      <h2 className={style.column_header}>
        <span>§ 17 </span>
        <span>Final provisions</span>
      </h2>
      <ol className={style.list}>
        <li>
          In matters not regulated by these Regulations, the relevant provisions of Polish law, and in particular of the Civil Code, shall apply.
        </li>
        <li>
          If any provision of these Terms and Conditions is found to be invalid or ineffective, the remaining provisions shall remain in full force and effect. The provisions of these Regulations that are invalid or ineffective shall be replaced by provisions that are valid under the law and fully effective and most similar to them.
        </li>
        <li>
          Any disputes arising between the Operator and the User shall be submitted to the court having jurisdiction over the seat of the Operator.
        </li>
      </ol>
    </LegalTermWrapper>
  );
};

export default Statute;
