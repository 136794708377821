import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink } from 'react-router-dom';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import cn from 'classnames';

import { ReactComponent as Logo } from 'assets/img/avalio-logo.svg';
import { LanguageSwitcher, useIsMobile } from 'components';
import { Button } from 'components';

import style from './Header.module.scss';

const Header = ({ transparent }) => {
  const { t } = useTranslation('common');
  const isMobile = useIsMobile();

  const [isOpen, setIsOpen] = useState(false);
  const headerRef = useRef();

  const onClick = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      disableBodyScroll(document.body);
      document.body.classList.add(style.noScroll);
    } else {
      enableBodyScroll(document.body);
      document.body.classList.remove(style.noScroll);
    }
  }, [headerRef, isOpen]);

  useEffect(() => {
    return () => {
      enableBodyScroll(document.body);
      document.body.classList.remove(style.noScroll);
    };
  }, []);

  const loginButtonHref =
    import.meta.env.VITE_NODE_ENV === 'development' ? 'http://localhost:2137/' : 'https://app.avalio.io/login';

  return (
    <header
      className={cn(style.container, { [style.transparent]: transparent })}
      ref={headerRef}
    >
      <Link
        to={'/'}
        onClick={onClick}
        className={style.topElement}
      >
        <Logo className={style.logo} />
      </Link>
      <nav className={cn(style.nav, { [style.isOpen]: isOpen })}>
        <NavLink
          to={'/'}
          exact
          className={style.link}
          activeClassName={style.active}
          onClick={onClick}
        >
          {t('navigation.home')}
        </NavLink>
        <NavLink
          to={'/benefits'}
          className={style.link}
          activeClassName={style.active}
          onClick={onClick}
        >
          {t('navigation.benefits')}
        </NavLink>
        <NavLink
          to={'/about'}
          className={style.link}
          activeClassName={style.active}
          onClick={onClick}
        >
          {t('navigation.about')}
        </NavLink>
        <NavLink
          to={'/contact'}
          className={style.link}
          activeClassName={style.active}
          onClick={onClick}
        >
          {t('navigation.contact')}
        </NavLink>
        <a
          href={loginButtonHref}
          onClick={onClick}
          style={{ textDecoration: 'none' }}
        >
          <Button
            label={t('navigation.login')}
            className={style.loginButton}
            empty
          />
        </a>
        <Button
          label={t('navigation.scheduleDemo')}
          to={'/contact'}
        />
        <LanguageSwitcher className={style.languageSwitcher} align={isMobile ? 'left' : 'right'} />
      </nav>
      <button
        onClick={() => setIsOpen((prev) => !prev)}
        className={cn(
          style.mobileMenuButton,
          {
            [style.buttonActive]: isOpen
          },
          style.topElement
        )}
      >
        <span className={style.content} />
      </button>
    </header>
  );
};

export default Header;
