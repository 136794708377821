import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import dashboardScreenshot from 'assets/img/ss-dashboard.webp';
import ordersScreenshot from 'assets/img/ss-orders.webp';
import statisticsScreenshot from 'assets/img/ss-statistics.webp';

import style from './Steps.module.scss';

const Steps = () => {
  const { i18n, t } = useTranslation('page');

  const data = useMemo(
    () => [
      { id: 0, title: t('home.steps.step1.title'), description: t('home.steps.step1.description'), image: dashboardScreenshot },
      { id: 1, title: t('home.steps.step2.title'), description: t('home.steps.step2.description'), image: ordersScreenshot },
      { id: 2, title: t('home.steps.step3.title'), description: t('home.steps.step3.description'), image: statisticsScreenshot }
    ],
    [i18n.language]
  );

  return (
    <div className={style.container}>
      <div className={style.content}>
        {data?.map((item, index) => (
          <div
            className={classNames(style.step, {
              [style.stepReverse]: index % 2 === 1
            })}
            key={index}
          >
            <div className={style.column}>
              <div className={style.titleRow}>
                <div className={style.number}>{index + 1}</div>
                <h1 className={style.title}>{item.title}</h1>
              </div>
              <div className={style.text}>{item.description}</div>
            </div>
            <div className={style.imageContainer}>
              <img
                src={item.image}
                alt={item.title}
                className={style.image}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Steps;
