import { useTranslation } from 'react-i18next';

import PrivacyPolicyEN from './locales/en';
import PrivacyPolicyPL from './locales/pl';

const content = {
  pl: < PrivacyPolicyPL />,
  en: < PrivacyPolicyEN />
};

const PrivacyPolicy = () => {
  const { i18n } = useTranslation();
  return content[i18n.language] || <PrivacyPolicyPL />;
};

export default PrivacyPolicy;
