import React from 'react';
import { useTranslation } from 'react-i18next';
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import logoArtysciUsmiechu from 'assets/logos/logo_artysci_usmiechu.webp';
import logoDemed from 'assets/logos/logo_demed.webp';
import logoDent from 'assets/logos/logo_dent.webp';
import logoDentalFraternity from 'assets/logos/logo_dental_fraternity.webp';
import logoDentaurus from 'assets/logos/logo_dentaurus.webp';
import logoDorotowskaDentalClinic from 'assets/logos/logo_dorotowska_dental_clinic.webp';
import logoFaceClinic from 'assets/logos/logo_face_clinic.webp';
import logoIdentical from 'assets/logos/logo_identical.webp';
import logoKSE from 'assets/logos/logo_kse.webp';
import logoMedicadent from 'assets/logos/logo_medicadent.webp';
import logoPlatinum from 'assets/logos/logo_platinum.webp';
import logoProOrtodont from 'assets/logos/logo_pro_ortodont.webp';
import logoSzpitalDworska from 'assets/logos/logo_szpital_dworska.webp';
import logoZyrafaZebala from 'assets/logos/logo_zyrafa_zebala.webp';

import 'swiper/css';

import style from './Logos.module.scss';

const logos = [
  { id: 0, src: logoArtysciUsmiechu, alt: 'Artyści Uśmiechu' },
  { id: 1, src: logoDent, alt: 'Dent' },
  { id: 2, src: logoDentalFraternity, alt: 'Dental Fraternity' },
  { id: 3, src: logoDentaurus, alt: 'Dentaurus' },
  { id: 4, src: logoDorotowskaDentalClinic, alt: 'Dorotowska Dental Clinic' },
  { id: 5, src: logoIdentical, alt: 'Identical' },
  { id: 6, src: logoKSE, alt: 'KSE' },
  { id: 7, src: logoMedicadent, alt: 'Medicadent' },
  { id: 8, src: logoPlatinum, alt: 'Platinum' },
  { id: 9, src: logoProOrtodont, alt: 'Pro Ortodont' },
  { id: 10, src: logoZyrafaZebala, alt: 'Zyrafa Żebala' },
  { id: 11, src: logoDemed, alt: 'Demed' },
  { id: 12, src: logoSzpitalDworska, alt: 'Szpital Dworska' },
  { id: 13, src: logoFaceClinic, alt: 'Face Clinic' }
];

const Logos = () => {
  const { t } = useTranslation('page');
  return (
    <div className={style.container}>
      <h1 className={style.title}>{t('home.logos.title')}</h1>
      <div className={style.carousel}>
        <Swiper
          modules={[Autoplay]}
          spaceBetween={24}
          slidesPerView='auto'
          loop={true}
          autoplay={{
            delay: 1000,
            disableOnInteraction: false
          }}
          allowTouchMove={false}
          simulateTouch={false}
        >
          {logos.map((logo) => (
            <SwiperSlide
              key={logo.id}
              className={style.swiperSlide}
            >
              <div className={style.logoContainer}>
                <img
                  src={logo.src}
                  alt={logo.alt}
                  className={style.logo}
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default Logos;
