import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import intervalPlural from 'i18next-intervalplural-postprocessor';

import { initOptions } from './config';

if (process.env.NODE_ENV !== 'test') {
  intervalPlural.setOptions({
    intervalSeparator: ';',
    intervalRegex: /\((\S*)\).*?\[((.|\n)*)\]/,
    intervalSuffix: '_interval'
  });

  i18n.use(LanguageDetector).use(intervalPlural).use(initReactI18next).init(initOptions);
}

export default i18n;
