import { useTranslation } from 'react-i18next';

import StatuteEN from './locales/en';
import StatutePL from './locales/pl';

const content = {
  pl: <StatutePL />,
  en: <StatuteEN />
};

const Statute = () => {
  const { i18n } = useTranslation();
  return content[i18n.language] || <StatutePL />;
};

export default Statute;
