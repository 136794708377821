import { Link } from 'react-router-dom';

import { LegalTermWrapper } from 'components';

import style from 'src/components/layout/LegalTermWrapper/LegalTermWrapper.module.scss';

const PrivacyPolicy = () => {
    return (
        <LegalTermWrapper pageTitle={'Polityka prywatności'}>
            <p className={style.text}>
                Przedmiotowy dokument (dalej  jako „Polityka”) zawiera informacje dotyczące przetwarzania przez: CBM 16 Spółka z ograniczoną odpowiedzialnością z siedzibą w Jasionce, adres: 36-002 Jasionka 954 E, wpisaną do rejestru przedsiębiorców Krajowego Rejestru Sądowego przez Sąd Rejonowy w Rzeszowie, XII Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS: 0000809744, NIP: 5170402504, REGON: 384670823 o kapitale zakładowym 10.000 zł, (dalej jako „Administrator”) danych osobowych osób korzystających z Platformy Internetowej dostępnej pod adresem www.dentametr.pl (dalej jako „Platforma”). Polityka zostaje udostępniona w celu zapewnienia osobom, których dane osobowe są przetwarzane przez Administratora, jak najszerszej informacji o zakresie przetwarzanych danych, sposobach i zasadach przetwarzania danych oraz o prawach tych osób.
            </p>
            <p className={style.text}>
                Dane Osobowe Użytkowników są przetwarzane zgodnie z ustawą o ochronie danych osobowych z dnia 10 maja 2018r., a także rozporządzeniem Parlamentu Europejskiego I Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (dalej jako „RODO”), ustawą o świadczeniu usług drogą elektroniczną z dnia 18 lipca 2002 r. (Dz.U. Nr 144, poz. 1204 ze zm.) oraz ustawą z dnia 16 lipca 2004 r. – Prawo telekomunikacyjne (Dz. U. 2004 Nr 171 poz. 1800 ze zm.)
            </p>
            <p className={style.text}>
                Administrator dokłada szczególnej staranności w celu ochrony interesów osób, których dane dotyczą, a w szczególności zapewnia, że zbierane przez niego dane są przetwarzane zgodnie z prawem; zbierane dla oznaczonych, zgodnych z prawem celów i niepoddawane dalszemu przetwarzaniu niezgodnemu z tymi celami; merytorycznie poprawne i adekwatne w stosunku do celów, w jakich są przetwarzane oraz przechowywane w postaci umożliwiającej identyfikację osób, których dotyczą, nie dłużej niż jest to niezbędne do osiągnięcia celu przetwarzania oraz w minimalnej ilości.
            </p>
            <p className={style.text}>
                Wszelkie słowa, wyrażenia i akronimy występujące na niniejszej stronie i rozpoczynające się z dużej litery (np. Użytkownik, Oferta Handlowa Dostawcy, Towary, Usługi) należy rozumieć zgodnie z ich definicją zawartą w Regulaminie Platformy dostępnego pod adresem <Link to='/statute'>avalio.io/statute</Link>
            </p>
            <h2 className={style.header}>I.	ZAKRES ZASTOSOWANIA</h2>
            <p className={style.text}>
                Niniejsza Polityka dotyczy przetwarzania Danych Osobowych w związku z korzystaniem
                z Platformy oraz zamawianiem Towarów przez Użytkowników za pośrednictwem Platformy.
            </p>
            <h2 className={style.header}>II. PODSTAWY PRAWNE, CELE, ZASADY ORAZ CZAS PRZETWARZANIA DANYCH NA PLATFORMIE</h2>
            <ol className={style.list}>
                <li>
                    Użytkownicy mogą pozostawić swoje dane na Platformie w następujących przypadkach:
                    <ol className={style.second_list}>
                        <li>wypełnienia Formularza rejestracji i rejestracji Konta na Platformie;</li>
                        <li>zapisania się na newsletter; </li>
                        <li>kontaktu telefonicznego, mailowego, wypełniania Formularza zamówienia.</li>
                    </ol>
                </li>
                <li>
                    Dane Osobowe przetwarzane są przez Administratora w celu:
                    <ol className={style.second_list}>
                        <li>
                            nawiązywania, ukształtowania treści stosunku prawnego i jego zmiany, a także do wykonywania praw i obowiązków wynikających z zawartej pomiędzy Użytkownikiem a Administratorem/Operatorem umowy, (art. 6 ust. 1 lit. b) RODO) jak również przekazywania Użytkownikowi informacji i zamawianych przez niego Towarów, co jest niezbędne do wykonywania wzajemnych zobowiązań umownych oraz celu rozpatrywania skarg i reklamacji związanych z zawieranymi umowami (przetwarzanie jest niezbędne do wypełnienia obowiązku prawnego ciążącego na Administratorze – art. 6 ust. 1 lit. c) RODO) – przez czas niezbędny do realizacji umowy, a po jej zakończeniu dane będą przechowywane przez okres niezbędny do wykazania prawidłowości wykonania zobowiązań wynikających z umowy, aż do upływu terminów wskazanych w przepisach o archiwizacji oraz upływu terminów dochodzenia roszczeń z tytułu umowy;
                        </li>
                        <li>
                            przekazywania Użytkownikowi (w sposób zgodny z obowiązującymi przepisami) materiałów marketingowych oraz informacji, instrukcji i wskazówek niezbędnych do realizacji umowy – przetwarzanie danych następuje wówczas za jego zgodą (art. 6 ust. 1 lit. a) RODO) i/lub w uzasadnionym interesie Administratora jakim jest doskonalenie świadczonych usług/oferowanych Towarów oraz marketing bezpośredni (art. 6 ust. 1 lit. f) RODO) przez czas niezbędny do realizacji prawnie uzasadnionego interesu Administratora do czasu zgłoszenia uzasadnionego sprzeciwu przez Użytkownika, zaś w przypadku marketingu bezpośredniego nie dłużej niż do czasu wyrażenia sprzeciwu;                        </li>
                        <li>
                            wykonywania innych ustawowych obowiązków Administratora, w szczególności podatkowych i sprawozdawczych (art. 6 ust.1 lit. c) RODO) – przez czas niezbędny do realizacji ustawowych obowiązków Administratora, w szczególności do czasu upływu terminu przedawnienia zobowiązań podatkowych;                        </li>
                        <li>
                            doskonalenia funkcjonowania Platformy oraz zakresu Oferty Handlowej Dostawcy, co stanowi uzasadniony interes Administratora (art. 6 ust. 1 lit. f) RODO); Dane Osobowe są wówczas przetwarzane przez czas niezbędny do realizacji prawnie uzasadnionego interesu Administratora lub do czasu złożenia uzasadnionego sprzeciwu przez Użytkownika;                        </li>
                        <li>
                            wykonywania wzajemnych zobowiązań umownych, sporządzania, wykonywania lub obrony roszczeń wynikających z umowy (art. 6 ust. 1 lit. b) RODO), spełnienia ciążących na Administratorze obowiązków ustawowych (art. 6 ust. 1 lit. c) RODO), jak również prowadzone jest w uzasadnionym interesie Administratora (w celu zapewnienia wysokiej jakości Usług świadczonych na rzecz Użytkownika) (art. 6 ust. 1 lit. f) RODO) - przez czas niezbędny do realizacji umowy, a po jej zakończeniu dane będą przechowywane przez okres niezbędny do wykazania prawidłowości wykonania zobowiązań wynikających z umowy aż do upływu terminów wskazanych w przepisach o archiwizacji; w przypadku realizacji obowiązków ustawowych przez Administratora przetwarzanie Danych Osobowych będzie realizowane przez czas niezbędny do realizacji ustawowych obowiązków Administratora; w przypadku zapewnienia bezpieczeństwa przez czas niezbędny do realizacji prawnie uzasadnionego interesu Administratora lub do czasu złożenia uzasadnionego sprzeciwu przez Użytkownika;                        </li>
                        <li>
                            w przypadku wyrażenia dobrowolnych i opcjonalnych zgód (art. 6 ust. 1 lit. a) RODO) oraz art. 10 ustawy z dnia 18.07.2002 r o świadczeniu usług drogą elektroniczną oraz art. 172 ustawy z dnia 16.07.2004 r. – Prawo telekomunikacyjne) dane będą przetwarzane także w celach marketingowych polegających na np. informowaniu o produktach sprzedawanych/usługach świadczonych przez Administratora /Operatora, również na podstawie osobnych zgód wyrażonych za pomocą wiadomości e-mail lub w trakcie rozmowy telefonicznej – do czasu cofnięcia zgody.                        </li>
                    </ol>
                    <p className={style.text}>Po upływie okresu przetwarzania, Dane Osobowe są niezwłocznie usuwane lub anonimizowane. </p>
                </li>
                <li className={style.extra_margin}>
                    Administrator będzie przetwarzał następujące Dane Osobowe przekazane przez Użytkowników Platformy:
                    <ol className={style.second_list}>
                        <li>nazwę firmy,</li>
                        <li>imię i nazwisko ( m.in. osoby uprawnionej do reprezentacji),</li>
                        <li>numer do celów ewidencji podatkowej i statystycznej,</li>
                        <li>numer wpisu do rejestru podmiotów prowadzących działalność gospodarczą,</li>
                        <li>adres do korespondencji,</li>
                        <li>adres poczty elektronicznej,</li>
                        <li>numer telefonu.</li>
                    </ol>
                </li>
                <li>
                    W odniesieniu do Danych Osobowych dotyczących odwiedzin Użytkownika na Platformie Administrator może (w sytuacji gdy jest to wymagane i uzyskał w tym celu zgodę Użytkownika) pozyskiwać dane dotyczące urządzeń oraz sieci wykorzystywanych przez Użytkownika celem uzyskania dostępu do Oferty Handlowej Dostawcy zamieszczonej na Platformie oraz Towarów umieszczonych przez Użytkownika w Koszyku, także podglądu i realizacji złożonych Zamówień. Dane te mogą obejmować: adres IP Użytkownika, dane dotyczące logowania, rodzaju i wersji przeglądarki internetowej, rodzajów i wersji wtyczek wykorzystywanych przez przeglądarki internetowe, systemu operacyjnego oraz platformy, identyfikatora reklamowego, informacji na temat odwiedzin, w tym adresu URL witryny, na której kliknięty został link prowadzący do Platformy, poszukiwanych lub przeglądanych produktów, błędów pobierania danych, czasu odwiedzin na określonych stronach, interakcji z innymi stronami. Dane te pozyskiwane są przez Administratora, który pozyskuje te dane w szczególności za pośrednictwem plików cookie oraz innych technologii.
                </li>
                <li>
                    Podanie Danych Osobowych, o których mowa w pkt. 3. jest dobrowolne, ale niezbędne do świadczenia Usług przez Administratora/Operatora w ramach Platformy lub zawarcia Umowy sprzedaży Towarów. Każdorazowo zakres wymaganych danych wskazany jest także w Regulaminie Platformy oraz przed rozpoczęciem świadczenia określonej Usługi lub zawarciem Umów na stronie Platformy.
                </li>
                <li>
                    W związku z realizacją Usług oraz sprzedaży Towarów znajdujących się na Platformie, Dane Osobowe mogą być ujawniane następującym podmiotom:
                    <ol className={style.second_list}>
                        <li>Dostawcom</li>
                        <li>
                            w przypadku Użytkownika, który korzysta ze sposobu dostawy Towaru przesyłką kurierską, Administrator udostępnia zebrane dane osobowe Użytkownika wybranemu przewoźnikowi lub operatorowi pocztowemu realizującemu przesyłki na zlecenie Administratora, w zakresie i nie dłużej niż jest to niezbędne w celu realizacji zamówienia i dokonania dostawy zamówionego towaru;
                        </li>
                        <li>
                            usługodawcom Administratora (w szczególności w zakresie usług informatycznych, zagadnień technicznych, usług księgowo - finansowych oraz płatności);
                        </li>
                        <li>
                            osobom współpracującym z Administratorem w ramach Platformy na podstawie wydanego upoważnienia przez Administratora;
                        </li>
                        <li>organom państwowym, prokuraturze, policji, w przypadku, gdy jest to wymagane przepisami prawa.</li>
                    </ol>
                </li>
                <li>
                    Administrator informuje, że przekazywanie Danych Osobowych zewnętrznym odbiorcom będzie odbywało się wówczas gdy:
                    <ol className={style.second_list}>
                        <li>jest to konieczne do korzystania z usług podmiotu zewnętrznego, </li>
                        <li>jest to konieczne do umów zawartych z podmiotami zewnętrznymi,</li>
                        <li>jest to konieczne do prawidłowej realizacji i dostawy Towarów; </li>
                        <li>
                            jest to konieczne do celów analitycznych, w tym także w celu optymalizacji Platformy oraz świadczonych przez Administratora Towarów;                        </li>
                        <li>wynika to z przepisów prawa powszechnie obowiązującego;</li>
                        <li>jest to konieczne do obrony roszczeń lub praw Administratora, w tym w związku z toczącym się procesem; </li>
                        <li>nastąpiła okoliczność stanowiąca zagrożenie życia, zdrowia, mienia lub bezpieczeństwa.  </li>
                    </ol>
                </li>
                <li>
                    Zebrane dane osobowe nie są przekazywane do państw trzecich (tj. poza Europejski Obszar Gospodarczy) ani żadnej organizacji międzynarodowej.
                </li>
                <li>
                    Dane Osobowe będą przetwarzane w sposób zautomatyzowany w tym również w formie profilowania. Zautomatyzowane podejmowanie decyzji będzie odbywało się na poprzez weryfikację historii przeglądanych przez Użytkownika - Towarów dostępnych na Platformie. Z kolei konsekwencją takiego przetwarzania  Danych Osobowych będzie dostarczanie Użytkownikom zawartości merytorycznej związanej z użytkowaniem Platformy oraz dostosowywanie tych zawartości do indywidualnych preferencji Użytkownika.                </li>
            </ol>
            <h2 className={style.header}>III. PLIKI COOKIES I DANE EKSPLOATACYJNE</h2>
            <ol className={style.list}>
                <li>
                    Pliki Cookies (ciasteczka) to pliki tekstowe umieszczane przez serwer na urządzeniu, na którym działa Przeglądarka. Pliki Cookies stanowią dane informatyczne, w szczególności pliki tekstowe, które przechowywane są w urządzeniu końcowym Użytkownika (np. w pamięci komputera) i przeznaczone są do korzystania z Platformy.
                </li>
                <li>
                    Administrator wykorzystuje ciasteczka celu poprawy jakości funkcjonowania Platformy, dopasowania jego zawartości do preferencji Użytkownika, a także optymalizacji korzystania przez niego z Platformy. Za pośrednictwem ciasteczek możliwe jest także podtrzymanie sesji logowania na Platformie.                </li>
                <li>
                    Usługodawca przetwarza dane zawarte w plikach Cookies podczas korzystania przez odwiedzających ze strony internetowej  Platformy w następujących celach:                     <ol className={style.second_list}>
                        <li>identyfikacji Usługobiorców jako zalogowanych na Platformie i pokazywania, że są zalogowani;</li>
                        <li>zapamiętywania Towarów dodanych do Koszyka w celu złożenia Formularza zamówienia;</li>
                        <li>zapamiętywania danych z wypełnianych Formularzy zamówienia lub danych logowania na Platformie;</li>
                        <li>dostosowywania Oferty Handlowej Dostawcy do indywidualnych preferencji Użytkownika;</li>
                        <li>optymalizacji korzystania z Platformy;</li>
                        <li>
                            prowadzenia anonimowych statystyk przedstawiających sposób korzystania ze Platformy oraz badania potrzeb Użytkowników, z wyłączeniem personalnej identyfikacji Użytkownika.
                        </li>
                    </ol>
                </li>
                <li>
                    Standardowo większość przeglądarek internetowych dostępnych na rynku domyślnie akceptuje zapisywanie plików Cookies. Istnieje możliwość określenia warunków korzystania z plików Cookies za pomocą ustawień własnej przeglądarki internetowej. Oznacza to, że można np. częściowo ograniczyć (np. czasowo) lub całkowicie wyłączyć możliwość zapisywania plików Cookies na komputerze – w tym ostatnim wypadku jednak może to mieć wpływ na niektóre funkcjonalności Platformy (np. niemożliwym może okazać się przejście ścieżki zamówienia poprzez Formularz zamówienia).
                </li>
                <li>
                    Ustawienia przeglądarki internetowej w zakresie plików Cookies są istotne z punktu widzenia zgody na korzystanie z plików Cookies przez naszą Platformę – zgodnie z przepisami taka zgoda może być również wyrażona poprzez ustawienia przeglądarki internetowej.
                </li>
                <li>
                    Szczegółowe informacje na temat zmiany ustawień dotyczących plików Cookies oraz ich samodzielnego usuwania w najpopularniejszych przeglądarkach internetowych dostępne są w dziale pomocy przeglądarki internetowej oraz na poniższych stronach (wystarczy kliknąć w dany link):
                    <ol className={style.second_list}>
                        <li>
                            w przeglądarce Chrome <a href='https://support.google.com/chrome/answer/95647?hl=pl'>[link]</a>
                        </li>
                        <li>
                            w przeglądarce Firefox <a href='https://support.mozilla.org/pl/kb/ciasteczka'>[link]</a>
                        </li>
                        <li>
                            w przeglądarce Internet Explorer
                            <a href='https://support.microsoft.com/pl-pl/microsoft-edge/usuwanie-plik%C3%B3w-cookie-w-przegl%C4%85darce-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09'>
                                [link]
                            </a>
                        </li>
                        <li>
                            w przeglądarce Opera <a href='https://help.opera.com/pl/latest/web-preferences/#cookies'>[link]</a>
                        </li>
                        <li>
                            w przeglądarce Safari <a href='https://support.apple.com/pl-pl/guide/safari/sfri11471/mac'>[link]</a>
                        </li>
                        {/* <li>
                            in Samsung Internet browser
                            <a href='https://www.samsung.com/uk/support/mobile-devices/using-the-samsung-internet-app/'>[link]</a>
                        </li> */}
                    </ol>
                </li>
                <li>
                    Administrator zbiera również dane eksploatacyjne (tzw. logi – adres IP, domena), które przechowywane są przez czas nieokreślony i wykorzystywane wyłącznie do generowania statystyk pomocnych w administrowaniu Platformy. Dane te mają charakter zbiorczy i anonimowy, tj. nie zawierają cech identyfikujących osoby odwiedzające Platformę. Logi nie są ujawniane osobom trzecim.                </li>
            </ol>
            <h2 className={style.header}>IV. NEWSLETTER</h2>
            <ol className={style.list}>
                <li>
                    Do wysyłki newslettera wykorzystywany jest podany przez Użytkownika adres e-mail. Wymagane jest tutaj potwierdzenie Użytkownika polegające na wyrażeniu przez niego, jako właściciela adresu e-mail, dobrowolnej zgody na otrzymywanie newslettera.                </li>
                <li>
                    Użytkownik może w każdej chwili wypowiedzieć umowę o świadczenie usługi newslettera poprzez dezaktywację subskrypcji. Rezygnacja z newslettera następuje poprzez wysłanie oświadczenia woli na adres info@avalio.io lub poprzez odpowiedni link znajdujący się w stopce każdej wiadomości wysyłanej w ramach newslettera.
                </li>
            </ol>
            <h2 className={style.header}>
                V. PRAWO KONTROLI, DOSTĘPU DO TREŚCI SWOICH DANYCH, UZYSKANIA KOPII POPRAWIANIA DANYCH, ZŁOŻENIA SPRZECIWU
            </h2>
            <ol className={style.list}>
                <li>
                    Zgodnie z przepisami RODO, Użytkownikowi przysługują następujące prawa związane z kontrolą przetwarzania Danych Osobowych:
                    <ol className={style.second_list}>
                        <li>
                            prawo dostępu do treści Danych Osobowych (m. in. uzyskania informacji jakiego rodzaju Dane Osobowe są przetwarzane przez Administratora, otrzymania kopii swoich Danych Osobowych);                        </li>
                        <li>prawo żądania poprawienia, uaktualnienia lub sprostowania Danych Osobowych;</li>
                        <li>
                            prawo żądania usunięcia Danych Osobowych, jeżeli są one niekompletne, nieaktualne, nieprawdziwe lub zostały zebrane z naruszeniem przepisów prawa albo są zbędne do realizacji celu, dla którego zostały zebrane; niezależnie od powyższego Użytkownikowi przysługuje możliwość usunięcia Konta na Platformie (nie jest to jednak równoznaczne z usunięciem Danych Osobowych);                        </li>
                        <li>
                            prawo wniesienia skargi do organu nadzorczego zajmującego się ochroną danych osobowych – tj. Prezesa Urzędu Ochrony Danych Osobowych (np. w przypadku uznania, że przetwarzanie  Danych Osobowych narusza przepisy RODO lub inne przepisy dotyczące ochrony Danych Osobowych);                        </li>
                        <li>prawo do żądania ograniczenia przetwarzania Danych Osobowych; </li>
                        <li>
                            prawo wniesienia sprzeciwu wobec przetwarzania  Danych Osobowych, jeżeli przetwarzanie następuje na podstawie uzasadnionego interesu Administratora lub w celu marketingu bezpośredniego.                         </li>
                    </ol>
                </li>
                <li>
                    Jeżeli Dane Osobowe są przetwarzane na podstawie zgody, Użytkownikowi przysługuje:
                    <ol className={style.second_list}>
                        <li>prawo do wycofania zgody w dowolnej chwili; </li>
                        <li>prawo do przenoszenia Danych Osobowych. </li>
                    </ol>
                </li>
                <li>
                    W celu realizacji swoich uprawnień Użytkownik może skontaktować się z Administratorem za pośrednictwem kanałów komunikacji wskazanych w pkt. VII: „Dane Kontaktowe”.                </li>
                <li>
                    W przypadku odnotowania naruszenia ochrony Danych Osobowych, Użytkownikowi przysługuje skarga do organu nadzorczego, tj. Prezesa Urzędu Ochrony Danych Osobowych.                </li>
                <li>
                    Administrator zastrzega sobie prawo do nie zastosowania się do żądań Użytkownika dotyczących usunięcia, modyfikacji lub zmiany zakresu przetwarzania Danych Osobowych w przypadku naruszenia regulaminu Platformy lub w przypadku naruszenia przepisów powszechnie obowiązującego prawa. Zachowanie Danych Osobowych w formie niezmienionej będzie mieć na celu wyjaśnienie okoliczności wystąpienia ww. naruszeń i ustalenia odpowiedzialności za nie.                </li>
            </ol>
            <h2 className={style.header}>VI. BEZPIECZEŃSTWO DANYCH OSOBOWYCH</h2>
            <ol className={style.list}>
                <li>
                    Platforma może zawierać odnośniki do innych stron internetowych. Po przejściu na inne strony, należy zapoznać się z polityką prywatności tam ustaloną. Nasza Polityka Prywatności dotyczy tylko niniejszej Platformy.                </li>
                <li>
                    Administrator stosuje środki techniczne i organizacyjne zapewniające ochronę przetwarzanych danych osobowych odpowiednią do zagrożeń oraz kategorii danych objętych ochroną, a w szczególności zabezpiecza dane przed ich udostępnieniem osobom nieupoważnionym, zabraniem przez osobę nieuprawnioną, przetwarzaniem z naruszeniem obowiązujących przepisów oraz zmianą, utratą, uszkodzeniem lub zniszczeniem.                </li>
                <li>
                    Administrator wykorzystuje dostępne na rynku nowoczesne środki techniczne zapobiegające pozyskiwaniu i modyfikowaniu przez osoby nieuprawnione danych osobowych przesyłanych drogą elektroniczną (np. certyfikat SSL – Secure Socket Layer , zabezpieczenie Danych Osobowych przed nieuprawnionym dostępem; częste aktualizacje oprogramowania poprzez częste aktualizacje; dostęp do indywidualnego Konta  jedynie po zalogowaniu się loginem i hasłem; hasła są niejawne i są zapisane w bazie, jako tzw. hashe; szyfrowanie Danych Osobowych w bazie).                 </li>
            </ol>

            <h2 className={style.header}>VII. DANE KONTAKTOWE</h2>
            <p className={style.text}>
                W przypadku wszelkich pytań, wniosków i próśb dotyczących Twoich Danych Osobowych lub chęci skorzystania z określonego uprawnienia możesz skontaktować się z Administratorem  pod adresem info@avalio.io.            </p>
            <h2 className={style.header}>VIII. ZMIANY POLITYKI PRYWATNOŚCI</h2>
            <p className={style.text}>
                Administrator dąży do zapewnienia aktualności niniejszej Polityki i jej aktualizacji w przypadku zmiany przepisów prawa, orzecznictwa sądowego, wytycznych organów odpowiedzialnych za nadzór nad procesami przetwarzania danych osobowych, wprowadzenia Kodeksów Dobrych Praktyk (jeżeli Administrator będzie związany takimi kodeksami), zmiany technologii, sposobów, celów lub podstaw prawnych przetwarzania Danych Osobowych.            </p>
        </LegalTermWrapper>
    );
};

export default PrivacyPolicy;