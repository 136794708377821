import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { I18nextProvider } from 'react-i18next';
import { ConfigProvider } from 'antd';
import pl_PL from 'antd/lib/locale/pl_PL';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import i18next from 'i18next';

// import 'react-notifications-component/dist/theme.css';
// import 'semantic-ui-css/semantic.min.css';
// import 'react-notifications-component/dist/theme.css';
import './i18n/i18n';

import AppRouter from './app/AppRouter';
import { SeoHelmet } from './components';

import 'src/styles/reset.scss';
import 'src/styles/global.scss';
import 'src/styles/index.scss';

dayjs.extend(updateLocale);
dayjs.updateLocale('pl', {
  weekStart: 1
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <HelmetProvider>
        <ConfigProvider locale={pl_PL}>
          <SeoHelmet />
          <AppRouter />
        </ConfigProvider>
      </HelmetProvider>
    </I18nextProvider>
  </React.StrictMode>
);
