import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';

import { ReactComponent as Ambulatory } from 'assets/icons/ambu.svg';
import { ReactComponent as Clinic } from 'assets/icons/clinic.svg';
import { ReactComponent as Dental } from 'assets/icons/dental.svg';
import { ReactComponent as Hospital } from 'assets/icons/hospital.svg';
import { ReactComponent as Lab } from 'assets/icons/lab.svg';
import { ReactComponent as Nursing } from 'assets/icons/nursing.svg';
import { ReactComponent as Rehab } from 'assets/icons/rehab.svg';
import { ReactComponent as Vet } from 'assets/icons/vet.svg';
import { IconCard, useIsMobile } from 'components';

import 'swiper/css';
import 'swiper/css/pagination';

import style from './OurServices.module.scss';



const OurServices = () => {
  const isMediumScreen = useIsMobile(1500);
  const isMobileScreen = useIsMobile();
  const { i18n, t } = useTranslation('page');

  const data = useMemo(
    () => [
      { text: t('benefits.ourServices.text1'), icon: Hospital },
      { text: t('benefits.ourServices.text2'), icon: Dental },
      { text: t('benefits.ourServices.text3'), icon: Vet },
      { text: t('benefits.ourServices.text4'), icon: Nursing },
      { text: t('benefits.ourServices.text5'), icon: Clinic },
      { text: t('benefits.ourServices.text6'), icon: Lab },
      { text: t('benefits.ourServices.text7'), icon: Rehab },
      { text: t('benefits.ourServices.text8'), icon: Ambulatory }
    ],
    [i18n.language]
  );

  return (
    <div className={style.container}>
      <h3 className={style.title}>{t('benefits.ourServices.title')}</h3>
      <div className={style.wrapper}>
        <Swiper
          slidesPerView={isMobileScreen ? 1.7 : isMediumScreen ? 4 : 6}
          initialSlide={isMobileScreen ? 0 : isMediumScreen ? 1 : 2}
          spaceBetween={isMobileScreen ? 16 : 24}
          centeredSlides
        >
          {data.map((item) => (
            <SwiperSlide key={item.text}>
              <IconCard
                text={item.text}
                icon={item.icon}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default OurServices;
