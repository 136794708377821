import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Gear } from 'assets/icons/gear.svg';
import { ReactComponent as Squares } from 'assets/icons/squares.svg';
import { ReactComponent as Time } from 'assets/icons/time-fast.svg';
import { ReactComponent as Users } from 'assets/icons/users.svg';
import { IconCard, IconCardCircle } from 'components';

import style from './Icons.module.scss';

const Icons = () => {
  const { i18n, t } = useTranslation('page');

  const data = useMemo(
    () => [
      { text: t('home.icons.text1'), icon: Squares },
      { text: t('home.icons.text2'), icon: Gear },
      { text: t('home.icons.text3'), icon: Time },
      { text: t('home.icons.text4'), icon: Users }
    ],
    [i18n.language]
  );

  return (
    <div className={style.container}>
      {data.map((item) => (
        <IconCardCircle
          text={item.text}
          icon={item.icon}
          key={item.text}
          dark
        />
      ))}
    </div>
  );
};

export default Icons;
