import React from 'react';
import { useTranslation } from 'react-i18next';

import { Footer, Header, SeoHelmet } from 'components';

import pages from '../../dictionaries/pages.json';
import Post from './Post';

import style from './Stories.module.scss';

const Stories = () => {
    const { t } = useTranslation('page');
    return (
        <>
            <SeoHelmet title={t('stories.title')} />
            <div className={style.container}>
                <Header />
                <Post />
                <Footer />
            </div>
        </>
    );
};

export default Stories;