import { Link } from 'react-router-dom';

import { LegalTermWrapper } from 'components/index';

import style from 'src/components/layout/LegalTermWrapper/LegalTermWrapper.module.scss';
import { CONTACT_EMAIL } from 'src/constants/misc';

const Statute = () => {
  return (
    <LegalTermWrapper
      pageTitle={'Terms and Conditions'}
      documentTitle={'OGÓLNE WARUNKI ŚWIADCZENIA USŁUG APLIKACJI AVALIO'}
    >
      <p className={style.text}>
        Niniejszy dokument określa zasady, na jakich CBM 16 spółka z ograniczoną odpowiedzialnością̨ z siedzibą w Jasionce, wpisaną do rejestru przedsiębiorców prowadzonego przez Sąd Rejonowy w Rzeszowie, XIII Wydział Gospodarczy pod numerem KRS: 0000809744, NIP 5170402504, REGON 384670823, świadczy na rzecz swoich klientów usługi związane z udostępnieniem i zapewnieniem możliwości korzystania z Aplikacji Avalio, uzupełniając jednocześnie treść zawieranych Umów i stanowiąc ich integralną część.      </p>
      <h2 className={style.column_header}>
        <span>§ 1 </span>
        <span>PRZEDMIOT UMOWY</span>
      </h2>
      <ol className={style.list}>
        <li>
          Usługodawca oświadcza, iż jest właścicielem i jedynym uprawnionym z tytułu autorskich praw majątkowych do Aplikacji Avalio, zwanej dalej Aplikacją.
        </li>
        <li>
          Podstawowym celem Avalio jest zintegrowane i profesjonalne wsparcie Usługobiorcy w zarządzaniu stroną kosztową podmiotów działających w branży ochrony zdrowia, począwszy od tworzenia zamówień, poprzez ich śledzenie, odbieranie i opłacanie wraz z automatyzacją procesów poprzez wykorzystanie sztucznej inteligencji.
        </li>
        <li>
          Udostępnienie Aplikacji Usługobiorcy i korzystanie z niej przez Usługobiorcę następuje drogą elektroniczną (on-line) za pośrednictwem sieci Internet z wykorzystaniem serwera Usługodawcy, na którym Aplikacja jest zainstalowana – usługa typu Software as a Service, poprzez przekaz danych, przesyłanych i otrzymywanych za pomocą urządzeń do elektronicznego przetwarzania, włącznie z kompresją cyfrową i przechowywania danych, która jest w całości nadawana, odbierana lub transmitowana za pomocą sieci telekomunikacyjnej w rozumieniu ustawy z dnia 16 lipca 2004 r. – Prawo telekomunikacyjne.
        </li>
        <li>
          W ramach zawartej Umowy Usługodawca zapewnia na rzecz Usługobiorcy realizację Usług Abonamentowych związanych z Aplikacją.
        </li>
        <li>
          Strony określą w Umowie co najmniej następujące dane dotyczące udostępnianej Aplikacji:
          <ol className={style.second_list}>
            <li>
              ilość użytkowników oraz rodzaj kont użytkownika tworzonych dla Usługobiorcy;
            </li>
            <li>
              wysokość Abonamentu;
            </li>
            <li>
              czynności składające się na Wdrożenie oraz wynagrodzenie Usługodawcy za realizację Wdrożenia;
            </li>
            <li>
              dodatkowe usługi świadczone przez Usługodawcę na rzecz Usługobiorcy, które zostały indywidualnie uzgodnione;
            </li>
          </ol>
        </li>
        <li>
          W trakcie trwania Umowy Usługobiorca ma możliwość rozszerzenia Funkcjonalności Aplikacji. W tym celu Usługobiorca powinien zgłosić chęć dokonania zmian w zakresie Aplikacji Usługodawcy oraz dokonać potwierdzenia w zakresie wprowadzanych zmian, planowanego terminu ich Wdrożenia oraz Uruchomienia oraz wysokość Abonamentu po ich uwzględnieniu w formie mailowej, na adres e-mail info@avalio.io.
        </li>
      </ol>
      <h2 className={style.column_header}>
        <span>§ 2 </span>
        <span>WDROŻENIE</span>
      </h2>
      <ol className={style.list}>
        <li>Przybliżony termin wdrożenia i uruchomienia Aplikacji zostaną wskazane w Umowie.</li>
        <li>Usługodawca zobowiązany jest poinformować Usługobiorcę jakie warunki techniczne w zakresie niezbędnej infrastruktury technicznej, powinien spełnić, aby możliwe było wykonanie wdrożenia i uruchomienia Aplikacji, zaś Usługobiorca zobowiązany jest warunki te zapewnić.</li>
        <li>Wdrożenie obejmuje każdorazowo następujące czynności:
          <ol className={style.second_list}>
            <li>Analizę przedwdrożeniową;</li>
            <li>Zbudowanie zindywidualizowanej bazy produktów w Aplikacji;</li>
            <li>Konfigurację i parametryzację Aplikacji zgodnie z potrzebami Usługobiorcy;</li>
            <li>Szkolenie kadry placówki;</li>
          </ol>
        </li>
        <li>Usługobiorca może w ramach zawartej Umowy powierzyć Usługodawcy wykonanie innych czynności poza czynnościami, o których mowa w ust. 3 powyżej, w tym powierzyć dostarczenie dodatkowych narzędzi lub usług, koniecznych do spełnienia warunków technicznych niezbędnych do uruchomienia i poprawnego działania Aplikacji, co powinno zostać wyszczególnione w Umowie. </li>
        <li>Wykonanie wdrożenia i uruchomienia Aplikacji w terminie uzgodnionym w Umowie, uzależnione jest od współpracy Usługobiorcy z Usługodawcą. </li>
        <li>W przypadku wystąpienia przeszkód w wykonaniu Wdrożenia lub uruchomienia Aplikacji w uzgodnionym terminie, Strony mogą uzgodnić inny termin ich wykonania, co nie wymaga sporządzenia aneksu do Umowy.</li>
      </ol>
      <h2 className={style.column_header}>
        <span>§ 3 </span>
        <span>
          LICENCJA ORAZ ZASADY KORZYSTANIA
          Z APLIKACJI PRZEZ USŁUGOBIORCĘ
        </span>
      </h2>
      <ol className={style.list}>
        <li>Usługodawca oświadcza, że przysługują mu wyłączne autorskie prawa majątkowe do Aplikacji oraz, że jest uprawniony do rozporządzania prawami autorskimi do Aplikacji w zakresie niezbędnym do zawarcia i wykonywania Umowy, w tym udzielenia licencji na korzystanie z Aplikacji w zakresie wskazanym w niniejszym paragrafie.</li>
        <li>
          Usługodawca udziela Usługobiorcy w ramach Abonamentu, na czas trwania Umowy, niewyłącznej licencji na korzystanie z Aplikacji oraz wszystkich wprowadzonych w niej i udostępnionych Usługobiorcy Aktualizacji, obejmującej następujące pola eksploatacji:
          <ol className={style.second_list}>
            <li>prawo do korzystania z Aplikacji, zgodnie z jej charakterem i przeznaczeniem;</li>
            <li>prawo do wprowadzania danych do Aplikacji, zgodnie z jej charakterem i przeznaczeniem;</li>
          </ol>
        </li>
        <li>Aplikacja może być wykorzystywana przez Usługobiorcę wyłącznie w ramach prowadzonej przez niego działalności, na zasadach określonych w OWU, Umowie oraz zgodnie z powszechnie obowiązującymi przepisami prawa. </li>
        <li>Usługobiorcy nie przysługuje prawo pobrania (trwałego zwielokrotniania) Aplikacji oraz jej zainstalowania na innym serwerze. </li>
        <li>Usługobiorcy nie przysługuje uprawnienie do korzystania z Aplikacji jako samodzielnego przedmiotu obrotu. Zabronione jest sprzedawanie, najmowanie, dzierżawienie, sublicencjonowanie, przenoszenie praw i obowiązków wynikających z zawartej Umowy oraz udostępnianie w jakiejkolwiek formie Aplikacji, jak i jej części osobom trzecim, w tym w Internecie oraz w postaci elektronicznej. </li>
        <li>Usługobiorca nie jest uprawniony do przekazywania danych dostępowych do Aplikacji podmiotom trzecim, w tym osobom innym niż Użytkownicy, dla których zostały utworzone konta w Aplikacji. </li>
        <li>Usługobiorca nie ma prawa do dekompilacji, dezasemblacji ani innych podobnych czynności (reverse engineering), jak i innych czynności związanych z nieautoryzowanym dostępem do zawartości baz danych lub dołączaniem innego oprogramowania wykorzystującego zawartość baz danych, o ile czynności takie nie są dozwolone przez bezwzględnie obowiązujące przepisy prawa. </li>
        <li>Usługodawcy zakazane jest umieszczanie w Aplikacji treści o charakterze bezprawnym, w szczególności treści naruszających dobra osobiste osób trzecich, nieprawdziwych, wulgarnych, sprzecznych z dobrymi obyczajami, naruszających zasady współżycia społecznego, wprowadzających w błąd lub mogących narazić Usługodawcę lub osoby trzecie na powstanie szkody. Usługodawca nie ponosi odpowiedzialności za umieszczanie w Aplikacji przez Usługobiorcę treści zakazanych.</li>
      </ol>
      <h2 className={style.column_header}>
        <span>§ 4 </span>
        <span>ODPOWIEDZIALNOŚĆ USŁUGODAWCY</span>
      </h2>
      <ol className={style.list}>
        <li>Usługodawca ponosi odpowiedzialność za nieprawidłowe wykonanie lub niewykonanie Umowy, wyłącznie w granicach szkody wyrządzonej wskutek winy umyślnej lub rażącego niedbalstwa.</li>
        <li>Usługodawca ponosi odpowiedzialność wyłącznie w granicach poniesionej przez Usługobiorcę szkody rzeczywistej, z wyłączeniem odpowiedzialności za utracone korzyści lub inne szkody nie będące rzeczywistą stratą Usługobiorcy.</li>
        <li>Odpowiedzialność Usługodawcy w każdym wypadku ograniczona jest do kwoty trzykrotności Abonamentu. </li>
        <li>
          Usługodawca nie ponosi odpowiedzialności za niewykonanie lub nieprawidłowe wykonanie Umowy, w przypadku wystąpienia szkody spowodowanej:
          <ol className={style.second_list}>
            <li>nieprawidłowym użytkowaniem Aplikacji przez Usługobiorcę;</li>
            <li>udostępnieniem przez Usługobiorcę osobom trzecim danych dostępowych do Aplikacji;</li>
            <li>czynnikami zewnętrznymi lub leżącymi po stronie Usługobiorcy lub podmiotów trzecich Usługobiorcy;</li>
            <li>ingerencją Usługobiorcy lub osób trzecich powiązanych z Usługobiorcą w infrastrukturę techniczną skonfigurowaną przez Usługodawcę w ramach wykonanego Wdrożenia lub dokonaniem niedozwolonej modyfikacji Aplikacji;</li>
            <li>brakiem nieprzerwanego dostępu do łącza internetowego Usługobiorcy;</li>
            <li>przyczynami leżącymi po stronie oprogramowania komputerowego Usługobiorcy;</li>
          </ol>
        </li>
      </ol>
      <h2 className={style.column_header}>
        <span>§ 5 </span>
        <span>POSTĘPOWANIE REKLAMACYJNE</span>
      </h2>
      <ol className={style.list}>
        <li>Usługobiorca jest uprawniony do złożenia reklamacji dotyczącej sposobu i jakości świadczonych Usług Abonamentowych.</li>
        <li>Reklamacja może zostać złożona przez Usługobiorcę w dowolnej formie, jednakże w celu usprawnienia postępowania reklamacyjnego zaleca się jej złożenie w formie elektronicznej na adres e-mail Usługodawcy: info@avalio.io  lub pisemnej na adres siedziby Usługodawcy.</li>
        <li>Usługobiorca powinien zawrzeć w reklamacji opis problemu, w związku, z którego zaistnieniem składa reklamację.</li>
        <li>Usługodawca zobowiązany jest rozpatrzyć zgłoszoną przez Usługobiorcę reklamację bez zbędnej zwłoki, jednakże nie później niż w terminie 7 dni i poinformować Usługobiorcę o sposobie jej rozpatrzenia, za pośrednictwem poczty elektronicznej lub w formie pisemnej, w zależności od formy zgłoszenia reklamacji, na adres wskazany przez Usługobiorcę w reklamacji.</li>
      </ol>
      <h2 className={style.column_header}>
        <span>§ 6 </span>
        <span>CZAS TRWANIA UMOWY I JEJ ROZWIĄZANIE</span>
      </h2>
      <ol className={style.list}>
        <li>Umowa w zakresie świadczenia usług dostępu do Aplikacji zawarta jest na czas określony w Umowie.</li>
        <li>Usługobiorca zobowiązuje się do niewypowiadania Umowy w okresie jej obowiązywania na czas określony wskazany w ust. 1 powyżej.</li>
        <li>Wyjątkiem od §7(2) jest okres trzech miesięcy od momentu podpisania Umowy podczas którego Usługobiorca ma prawo do wypowiedzenia Umowy oraz otrzymania zwrotu części Abonamentu za pozostały, niewykorzystany czas trwania Umowy.</li>
        <li>
          W przypadku zalegania przez Usługobiorcę z płatnością Abonamentu przez okres powyżej
          14 dni Usługodawcy niezależnie od uprawnienia do rozwiązania Umowy ze skutkiem natychmiastowym zgodnie z postanowieniami Umowy, przysługuje prawo do wstrzymania świadczenia na rzecz Usługobiorcy Usług będących przedmiotem Umowy, co może wiązać się z brakiem możliwości korzystania przez Usługobiorcę z funkcjonalności Aplikacji.
        </li>
        <li>W sytuacji, o której mowa w ust. 1 powyżej, Usługodawca zobowiązany jest przywrócić dostęp do usług będących przedmiotem Umowy, w tym do funkcjonalności Aplikacji najpóźniej kolejnego dnia roboczego następującego po dniu dokonania przez Usługobiorcę płatności zaległego Abonamentu. Za moment zapłaty uważa się dzień uznania rachunku bankowego Usługodawcy.</li>
        <li>W przypadku zalegania przez Usługobiorcę z płatnością Abonamentu, Usługodawca jest uprawniony do wprowadzenia do Aplikacji począwszy od dnia następującego po dniu płatności, komunikatu informującego Usługobiorcę o występującej zaległości w płatności Abonamentu, co może wpłynąć na komfort użytkowania przez Usługobiorcę Aplikacji.</li>
      </ol>
      <h2 className={style.column_header}>
        <span>§ 7 </span>
        <span>KLAUZULA POUFNOŚCI</span>
      </h2>
      <ol className={style.list}>
        <li>Dla celów Umowy, jako „Informacje Poufne” traktowane będą wszelkie informacje wzajemnie sobie przekazane przez Strony (zarówno przed zawarciem Umowy, m.in. w trakcie negocjacji, jak i po jej zawarciu) dotyczące ich działalności handlowej, operacyjnej, spraw finansowych, ekonomicznych, technologicznych, organizacyjnych oraz inne informacje dotyczące Stron, podmiotów z nimi powiązanych, ich klientów, doradców oraz wszystkich innych osób związanych odpowiednimi umowami ze Stronami przekazane przez Strony w formie pisemnej, elektronicznej, jak i ustnej, w tym w szczególności informacje uzyskane od Stron oraz ich pracowników i współpracowników, niezależnie od tego, czy taka informacja wyraźnie została oznaczona jako Informacja Poufna.</li>
        <li>
          Zobowiązanie nie ma zastosowania do informacji, które:
          <ol className={style.second_list}>
            <li>były powszechnie dostępne w czasie ich ujawnienia;</li>
            <li>były w posiadaniu Stron przed ich udostępnieniem przez Strony, ich udziałowców/akcjonariuszy lub inne osoby;</li>
            <li>zostały uzyskane od strony trzeciej, uprawnionej do jej udzielenia;</li>
            <li>zostały ujawnione przez Strony, jak i w jej imieniu, w wykonaniu obowiązku ich ujawnienia na podstawie obowiązujących przepisów prawa;</li>
          </ol>
        </li>
        <li>
          W okresie obowiązywania Umowy oraz w okresie 12 (dwunastu) miesięcy po wygaśnięciu lub rozwiązaniu Umowy, każda ze Stron zobowiązuje się do:
          <ol className={style.second_list}>
            <li>zachowania w tajemnicy wszystkich Informacji Poufnych;</li>
            <li>niekorzystania z uzyskanych Informacji Poufnych w jakikolwiek inny sposób i w żadnym innym celu niż na potrzeby realizacji Umowy, a w szczególności w celu sprzecznym z interesem drugiej Strony;</li>
            <li>niewykorzystywania uzyskanych Informacji Poufnych na szkodę drugiej Strony, w szczególności poprzez zachowanie stanowiące czyn nieuczciwej konkurencji zgodnie z zapisami ustawy z dnia 16 kwietnia 1993 roku o zwalczaniu nieuczciwej konkurencji;</li>
            <li>nieprzekazywania Informacji Poufnych osobom trzecim bez uzyskania uprzedniej pisemnej zgody drugiej Strony;</li>
            <li>zapewnienia, że w wypadku przekazania lub udostępnienia Informacji Poufnych osobom trzecim za zgodą drugiej Strony, osoby te zostaną zobowiązane do podpisania odpowiedniego porozumienia o poufności. Strona ponosi odpowiedzialność za naruszenie przez osoby, którym przekazała Informacje Poufne, obowiązku zachowania poufności; </li>
          </ol>
        </li>
      </ol>
      <h2 className={style.column_header}>
        <span>§ 8 </span>
        <span>PRZETWARZANIE DANYCH OSOBOWYCH</span>
      </h2>
      <ol className={style.list}>
        <li>Będąc administratorem danych osobowych Usługodawca, na warunkach oznaczonych w  Umowie oraz na podstawie art. 28 ust. 3 RODO Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych (Dz. U. UE. L 119/1 z 4 maja 2016 r.; dalej: ,,RODO”), powierza Usługobiorcy przetwarzanie danych osobowych w zakresie i celu określonych w Umowie.</li>
        <li>Na Usługodawcy spoczywa obowiązek pozyskania danych osobowych, które będą powierzone podmiotowi, któremu będą powierzone dane osobowe do przetwarzania na mocy Umowy, zgodnie z przepisami obowiązującego prawa. Usługobiorca nie ponosi odpowiedzialności za wszelkie zaniedbania wykonania tego obowiązku przez Usługodawcę.</li>
        <li>
          Przez okres obowiązywania Umowy Usługobiorca na podstawie Umowy będzie przetwarzał następujące kategorie danych osobowych (zwykłych):
          <ol className={style.second_list}>
            <li>dane pracowników i osób współpracujących ze Usługodawcą na podstawie umów cywilnoprawnych, zawierające: imię i nazwisko, numer telefonu oraz adres e-mail – w zakresie dotyczącym utworzenia kont użytkowników Aplikacji oraz przetwarzania tych danych jako osób kontaktowych przedsiębiorstwa Usługodawcy;</li>
            <li>dane dostawców (kontrahentów, klientów) rejestrujących się Aplikacji, zawierające: nazwę firmy, adres siedziby, NIP, adres strony internetowej, imię i nazwisko osoby kontaktowej, telefon stacjonarny osoby kontaktowej, telefon komórkowy osoby kontaktowej, adres e-mail osoby kontaktowej i inne dane osobowe dostawców przetwarzane przez Usługodawcę;</li>
            <li>dane potencjalnych dostawców (kontrahentów, klientów) w związku z dystrybucją zapytań ofertowych w Aplikacji: nazwę firmy, adres siedziby, NIP, adres strony internetowej, imię i nazwisko osoby kontaktowej, telefon stacjonarny osoby kontaktowej, telefon komórkowy osoby kontaktowej, adres e-mail osoby kontaktowej i inne dane osobowe pozyskiwane ze źródeł powszechnie dostępnych;</li>
          </ol>
        </li>
        <li>Dane osobowe określone w ust. 2 przetwarzane będą w formie papierowej oraz z wykorzystaniem systemów informatycznych (w formie elektronicznej). Przetwarzanie danych będzie obejmowało w szczególności takie operacje jak ich: zbieranie, utrwalanie, organizowanie, porządkowanie, przechowywanie, adaptowanie lub modyfikowanie, pobieranie, przeglądanie, wykorzystywanie, ujawnianie poprzez przesłanie, rozpowszechnianie lub innego rodzaju udostępnianie, dopasowywanie lub łączenie, ograniczanie, usuwanie lub niszczenie. </li>
        <li>Dane osobowe określone w ust. 2 przetwarzane będą w celu realizacji przedmiotu Umowy. </li>
        <li>Usługobiorca będzie przetwarzał powierzone mu dane osobowe wyłącznie na udokumentowane polecenie Usługodawcy. W razie wątpliwości przyjmuje się, że Umowa stanowi udokumentowane polecenie przetwarzania danych, o których mowa w ust. 2, zwłaszcza tych, jakie związane będą z użyciem Aplikacji.</li>
        <li>Usługobiorca zobowiązuje się przetwarzać powierzone mu dane osobowe zgodnie z Umową, RODO oraz innymi przepisami prawa powszechnie obowiązującego, chroniącymi prawa osób, których dane dotyczą. </li>
      </ol>
      <h2 className={style.column_header}>
        <span>§ 9 </span>
        <span>OCHRONA DANYCH OSOBOWYCH</span>
      </h2>
      <ol className={style.list}>
        <li>Usługobiorca zapewnia gwarancję – w szczególności poprzez wiedzę fachową, doświadczenie, wiarygodność, wykwalifikowany personel i zasoby infrastrukturalne – stosowania środków technicznych i organizacyjnych, o których mowa w art. 36-39a UODO oraz w art. 32 RODO.</li>
        <li>
          Usługobiorca zobowiązuje się do:
          <ol className={style.second_list}>
            <li>ograniczenia dostępu do powierzonych danych osobowych wyłącznie do pracowników i współpracowników posiadających upoważnienie do przetwarzania powierzonych danych osobowych wydane przez Usługobiorcę;</li>
            <li>stałego nadzorowania swoich pracowników lub osób współpracujących ze Usługobiorcą na podstawie Umowy cywilnoprawnej w zakresie zabezpieczenia powierzonych do przetwarzania danych osobowych;</li>
            <li>zobowiązania swoich pracowników lub współpracowników do zachowania powierzonych do przetwarzania danych osobowych w poufności;</li>
          </ol>
        </li>
        <li>Usługobiorca zobowiązany jest do niezwłocznego poinformowania Usługodawcy o jakimkolwiek postępowaniu lub orzeczeniu dotyczącym danych osobowych, stwierdzonym naruszeniu ochrony danych osobowych, zapowiedzi albo rozpoczęcia przez organ nadzorczy kontroli lub postępowania wyjaśniającego dotyczącego danych osobowych, jednak nie później niż 7 dni od dnia powzięcia wiadomości o tym zdarzeniu.</li>
        <li>Usługobiorca udostępni Usługodawcy, na jego pisemne lub mailowe zgłoszenie, wszelkie informacje niezbędne do wykazania spełnienia obowiązków wynikających z niniejszego paragrafu</li>
        <li>W przypadku stwierdzenia incydentu w zakresie bezpieczeństwa powierzonych informacji lub prawdopodobieństwa jego wystąpienia, Usługobiorca niezwłocznie przekaże wszelkie posiadane informacje o tym zdarzeniu Usługodawcy. Strony zobowiązują się do wzajemnej pomocy w wypełnianiu obowiązków notyfikacyjnych o tych incydentach wobec organów nadzorczych.</li>
        <li>Usługobiorca ponosi odpowiedzialność w zakresie przewidzianym w Ustawie i RODO jako podmiot, któremu powierzono dane do przetwarzania. Nie zwalnia to Usługodawcy z odpowiedzialności za zdarzenia, za które ponosi on odpowiedzialność jako administrator danych osobowych zgodnie z obowiązującymi przepisami o ochronie danych osobowych.</li>
        <li>W przypadku, w którym w wyniku działania lub zaniechania Usługobiorcy związanego z przetwarzaniem danych osobowych, Usługodawca poniósłby szkodę (w tym zostałaby na niego nałożona kara finansowa), Usługobiorca zobowiązany jest do jej niezwłocznego (nie później niż 30 dni) naprawienia. Przedmiotowe zobowiązanie nie wyłącza możliwości żądania przez Usługodawcę zadośćuczynienia i naprawy szkody przenoszącego wartość szkody na zasadach ogólnych z zastrzeżeniem pozostałych postanowień niniejszej Umowy.</li>
        <li>Mając na uwadze charakter przetwarzania, w przypadku żądania osoby, której dane będą przetwarzane przez Usługobiorcę w związku z ich powierzeniem na mocy Umowy, w zakresie wykonania jej praw określonych w Rozdziale III RODO, Usługobiorca dołoży starań, aby w miarę możliwości poprzez środki organizacyjne i techniczne pomóc Usługodawcy w wywiązaniu się z obowiązku odpowiadania na żądania takiej osoby. </li>
        <li>Mając na uwadze charakter przetwarzania oraz dostępne Usługobiorcy informacje, dołoży on starań w pomocy Usługodawcy w wywiązaniu się z obowiązków określonych w art. 32-36 RODO.</li>
        <li>Usługobiorca, najpóźniej w ciągu 7 dni licząc od daty wygaśnięcia lub rozwiązania Umowy, zobowiązany jest do zwrotu lub usunięcia, w zależności od żądania Usługodawcy, wszelkich powierzonych danych osobowych do Usługodawcy.</li>
      </ol>
      <h2 className={style.column_header}>
        <span>§ 10 </span>
        <span>POSTANOWIENIA KOŃCOWE</span>
      </h2>
      <ol className={style.list}>
        <li>Postanowienia OWU stanowią integralną część zawartych Umów i zachowują moc, o ile nie są sprzeczne z poszczególnymi postanowieniami Umowy.</li>
        <li>W razie rozbieżności między postanowieniami OWU a Umowy, postanowienia Umowy mają znaczenie rozstrzygające.</li>
        <li>Stronom nie przysługuje prawo przeniesienia praw i obowiązków wynikających z zawartej Umowy na podmioty trzecie, bez uzyskania pisemnej zgody drugiej Strony.</li>
        <li>Wszelkie kwestie związane z przetwarzaniem danych osobowych w związku z zawarciem i realizacją Umowy, w tym udostępnieniem Aplikacji, Strony uregulują w odrębnych od OWU oraz Umowy dokumentach.</li>
      </ol>
    </LegalTermWrapper>
  );
};

export default Statute;
