import React from 'react';
import { useTranslation } from 'react-i18next';

import style from './Video.module.scss';

const VIDEO_URL =
  'https://iframe.mediadelivery.net/embed/339821/c293bfdb-f25a-4b5c-9e46-7f7de18745c8?autoplay=false&loop=false&muted=true&preload=true&responsive=false';

const Video = () => {
  const { t } = useTranslation('page');

  return (
    <div className={style.container}>
      <div className={style.content}>
        <h1 className={style.title}>{t('home.video.title')}</h1>
        <div className={style.video}>
          <iframe
            role='video'
            src={VIDEO_URL}
            loading='lazy'
            allow='encrypted-media;'
            allowFullScreen={true}
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default Video;
