import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as TickGradient } from 'assets/icons/tick-gradient-animation.svg';
import { useIsMobile } from 'components';

import './animation.css';
import style from './OurVision.module.scss';


const OurVision = () => {
  const { t } = useTranslation('page');
  const isMobile = useIsMobile();
  const titleRef = useRef(null);
  const [isTickVisible, setIsTickVisible] = useState(false);

  useEffect(() => {
    const windowListener = () => {
      if (titleRef.current && titleRef.current.getBoundingClientRect().top <= 350) {
        setIsTickVisible(true);
      }
    };

    if (!isTickVisible) {
      addEventListener('scroll', windowListener);
    }

    return () => {
      removeEventListener('scroll', windowListener);
    };
  }, [isTickVisible]);

  return (
    <div className={style.container}>
      <p className={style.subtitle}>{t('about.ourVision.subTitle')}</p>
      <h4
        className={style.title}
        ref={titleRef}
      >
        {t('about.ourVision.title')}
      </h4>
      <div className={style.wrapper}>
        <div className={style.tickWrapper}>{(isMobile || isTickVisible) && <TickGradient className={style.tick} />}</div>
        <div className={style.box}>
          <p className={style.text}>
            {t('about.ourVision.text')}
          </p>
        </div>
      </div>
    </div>
  );
};

export default OurVision;
