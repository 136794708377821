import React from 'react';
import cn from 'classnames';

import style from './IconCardCircle.module.scss';

const IconCardCircle = ({ text, icon: Icon, className }) => {
    return (
        <div className={cn(style.container, className)}>
            <div className={style.iconContainer}>
                <Icon className={style.icon} />
            </div>
            <p className={cn(style.text)}>{text}</p>
        </div>
    );
};

export default IconCardCircle;