import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { ReactComponent as OctopusLogo } from 'assets/img/octopus-logo.svg';

import style from './TopSection.module.scss';

const TopSection = () => {
  const { t } = useTranslation('page');
  const containerRef = useRef();

  useEffect(() => {
    document.addEventListener('scroll', () => {
      const scrolled = window.scrollY;

      if (containerRef.current?.style?.backgroundPosition) {
        containerRef.current.style.backgroundPosition = 'bottom -' + scrolled * 0.1 + 'px';
      }
    });
  }, []);
  return (
    <div
      className={style.container}
      ref={containerRef}
    >
      <div className={style.box}>
        <h1 className={style.title}>{t('about.topSection.title')}</h1>
        <p className={style.text}>
          {t('about.topSection.text1')}
        </p>
        <div className={style.row}>
          <p className={cn(style.text, style.strong)}>{t('about.topSection.text2')}</p>
          <OctopusLogo className={style.logo} />
        </div>
      </div>
    </div>
  );
};

export default TopSection;
