import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import { wpAxiosInstance } from 'src/api/client';

import style from './Post.module.scss';

const WP_BLOG_API_URL = '/posts';

const Post = () => {
    const history = useHistory();
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);

    useEffect(() => {
        setLoading(true);
        const getData = async () => {
            const query = `/${id}`;
            try {

                const { data } = await wpAxiosInstance.get(WP_BLOG_API_URL + query,);
                setData(data);
            } catch (err) {
                console.log(err);
                history.push('/404');

            } finally {
                setLoading(false);
            }
        };
        getData();
    }, []);

    return (
        <div className={style.container}>
            <div className={style.content}>
                <h1 className={style.title}>{data?.title?.rendered}</h1>
                {data?.content?.rendered && (
                    <div
                        className={style.html}
                        dangerouslySetInnerHTML={{ __html: data?.content?.rendered }}
                    />
                )}
            </div>
        </div>
    );
};

export default Post;