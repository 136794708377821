import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Cost } from 'assets/icons/cost.svg';
import { ReactComponent as Damand } from 'assets/icons/damand.svg';
import { ReactComponent as Financing } from 'assets/icons/financing.svg';
import { ReactComponent as RealTime } from 'assets/icons/real-time.svg';
import { ReactComponent as Streamlined } from 'assets/icons/streamlined.svg';
import { ReactComponent as Supplier } from 'assets/icons/supplier.svg';

import style from './WhyAvalio.module.scss';

const WhyAvalio = () => {
  const { i18n, t } = useTranslation('page');

  const data = useMemo(
    () => [
      {
        icon: Streamlined,
        title: t('benefits.whyAvalio.services.title1'),
        text: t('benefits.whyAvalio.services.text1'),
      },
      {
        icon: Cost,
        title: t('benefits.whyAvalio.services.title2'),
        text: t('benefits.whyAvalio.services.text2'),
      },
      {
        icon: RealTime,
        title: t('benefits.whyAvalio.services.title3'),
        text: t('benefits.whyAvalio.services.text3'),
      },
      {
        icon: Damand,
        title: t('benefits.whyAvalio.services.title4'),
        text: t('benefits.whyAvalio.services.text4'),
      },
      {
        icon: Supplier,
        title: t('benefits.whyAvalio.services.title5'),
        text: t('benefits.whyAvalio.services.text5'),
      },
      {
        icon: Financing,
        title: t('benefits.whyAvalio.services.title6'),
        text: t('benefits.whyAvalio.services.text6'),
      }
    ],
    [i18n.language]
  );

  return (
    <div className={style.container}>
      <p className={style.subtitle}>{t('benefits.whyAvalio.subTitle')}</p>
      <h2 className={style.title}>{t('benefits.whyAvalio.title')}</h2>
      <div className={style.wrapper}>
        {data.map(({ title, text, icon: Icon }) => (
          <div
            className={style.card}
            key={title}
          >
            <Icon className={style.icon} />
            <h4 className={style.cardTitle}>{title}</h4>
            <p className={style.text}>{text}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WhyAvalio;
