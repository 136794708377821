import { getLocalStorageItem } from 'src/utils/storage';

import {
  aboutEN,
  aboutPL,
  benefitsEN,
  benefitsPL,
  commonEN,
  commonPL,
  contactEN,
  contactPL,
  homeEN,
  homePL,
  storiesEN,
  storiesPL
} from './locales';

const resources = {
  pl: {
    common: commonPL,
    page: {
      home: homePL,
      benefits: benefitsPL,
      about: aboutPL,
      contact: contactPL,
      stories: storiesPL
    }
  },
  en: {
    common: commonEN,
    page: {
      home: homeEN,
      benefits: benefitsEN,
      about: aboutEN,
      contact: contactEN,
      stories: storiesEN
    }
  }
};

export const languageStorageKey = 'i18nextLng';

export const ns = ['common'];

export const initOptions = {
  resources,
  fallbackLng: 'pl',
  lng: getLocalStorageItem(languageStorageKey) || 'pl',
  debug: process.env.NODE_ENV === 'development',
  load: 'languageOnly',
  ns,
  defaultNS: 'common',
  interpolation: {
    escapeValue: true,
    formatSeparator: ','
  }
};
